import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
  Input,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormControl, FormControlName, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  debounceTime,
  map,
  switchMap,
  distinctUntilChanged
} from "rxjs/operators";
import { CommonService } from "../common.service";
import { AppStateService } from "../appStateService";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { EMPTY } from "rxjs/internal/observable/empty";

@Component({
  selector: "app-add-subjects",
  templateUrl: "./add-subjects.component.html",
  encapsulation: ViewEncapsulation.None,

  styleUrls: ["./add-subjects.component.scss"]
})
export class AddSubjectsComponent implements OnInit {
  @Input() subject: any;
  @Input() proxyForm: any;
  @Output() OnSave = new EventEmitter();
  submitted = false;
  searchText;
  cid: any;
  @ViewChild("uid", { static: false }) uid: ElementRef;
  uidValue: any;
  minimumSearchLengthNotReached = false;
  noResultsFound = false;

  constructor(
    public activeModal: NgbActiveModal,
    private commonService: CommonService,
    public appStateService: AppStateService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  subjectsForm = new FormGroup({
    id: new FormControl(""),
    firstName: new FormControl("", [Validators.required, Validators.pattern('.*\\S+.*')]),
    lastName: new FormControl("", [Validators.required, Validators.pattern('.*\\S+.*')]),
    jobTitle: new FormControl("", Validators.required),
    type: new FormControl("", Validators.required),
    description: new FormControl(""),
    employeeUId: new FormControl("", Validators.required),
    otherType: new FormControl("", [Validators.required, Validators.pattern('.*\\S+.*')]),
    subjectRelationshipToOrganization: new FormControl("", Validators.required),
  });

  subjectTypes = [
    "Subject/Reported Party",
    "Witness",
    "Reported",
    "Attorney",
    "Victim",
    "Co-Worker",
    "Other",
    "Regulatory Body",
    "Management"
  ];

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
    })
    // call subjects type api
    if (this.subject) {
      this.subjectsForm.patchValue({
        id: this.subject.id,
        firstName: this.subject.firstName,
        lastName: this.subject.lastName,
        jobTitle: this.subject.jobTitle,
        type: this.subject.type,
        description: this.subject.description,
        employeeUId: this.subject.employeeUId,
        otherType: this.subject.otherType,
        subjectRelationshipToOrganization: this.subject.subjectRelationshipToOrganization
      });
      if (this.subject.employeeUId) {
        this.uidValue = this.subject.employeeUId;
      }
    }
  }
  get subjectsControl() {
    return this.subjectsForm.controls;
  }

  saveSubject() {
    this.submitted = true;
    if (this.subjectsForm.value.employeeUId && this.subjectsForm.value.type.toUpperCase() == 'TEAM MEMBER' && this.proxyForm && this.uidValue !== this.subjectsForm.value.employeeUId) {
      document.getElementsByClassName('proxy-uid')[0].classList.add('ng-invalid');
      this.toastr.error("Please verify 8-digit Team Member Number");
      return
    }
    if (this.subjectsControl.firstName.invalid ||
      this.subjectsControl.lastName.invalid ||
      (!this.proxyForm && this.appStateService.showSubjectRelationship && this.subjectsControl.subjectRelationshipToOrganization.invalid) ||
      this.subjectsControl.type.invalid || (this.subjectsForm.value.type == "Other" && this.subjectsControl.otherType.invalid) ||
      (this.subjectsForm.value.type.toUpperCase() == 'TEAM MEMBER' && this.proxyForm && (this.subjectsControl.employeeUId.invalid || !this.subjectsControl.employeeUId.value))
    ) {
      return;
    }
    else {
      if (this.proxyForm) {
       this.subjectsForm.value.subjectRelationshipToOrganization = this.subjectsForm.value.type;
       this.subjectsForm.value.type = "Involved Party";
      }
      this.OnSave.emit(this.subjectsForm.value);
      this.activeModal.close(this.subject);
    }
  }

  changeOption(subjectType) {
    if (this.subjectsForm.value.type !== subjectType) {
      if (this.proxyForm) {
        this.subjectsForm.reset({ id: this.subjectsForm.value.id });
      }
      this.subjectsForm.patchValue({ type: subjectType });
    }
    document.getElementById("addSubjects").scroll(0, 0);
  } 

  changeSubjectRelationshipOption(subjectRelationship) {
    if (this.subjectsForm.value.subjectRelationshipToOrganization !== subjectRelationship) {
      if (this.proxyForm) {
        this.subjectsForm.reset({ id: this.subjectsForm.value.id });
      }
      this.subjectsForm.patchValue({ subjectRelationshipToOrganization: subjectRelationship });
    }
    document.getElementById("addSubjectRelationship").scroll(0, 0);
  }

  search = (text$: any) => {
    return text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(searchText => this.data(searchText))
    );
  };

  data(searchText) {
    const searchTextLength = searchText.trim().length;
    this.minimumSearchLengthNotReached = (searchTextLength > 0) && (searchTextLength < 3);
    if (searchTextLength < 3) {
      return [];
    }
    const results = this.commonService
      .getNames(
        this.appStateService.customerId,
        searchText,
      )
      .pipe(map(item => item));

    results.subscribe(items => this.noResultsFound = items.length === 0);
    return results;
  }

  formatter = x => this.searchText;

  onNameChange(name) {
    this.subjectsForm.patchValue({
      firstName: name.firstName,
      lastName: name.lastName,
      jobTitle: name.jobTitle,
      employeeUId: name.employeeUId,
    });
  }

  on8digitChange(event) {
    if (this.proxyForm) {
      this.subjectsForm.get('employeeUId').setValidators([Validators.minLength(8), Validators.maxLength(8)]);
      this.subjectsForm.get('employeeUId').updateValueAndValidity();
    } else {
      this.subjectsForm.get('employeeUId').clearValidators;
      this.subjectsForm.get('employeeUId').updateValueAndValidity();
    }
  }

  getUser(uid) {
    if (uid && uid.value) {
      this.getEmployee(uid);
      this.uidValue = uid.value;
    }
  }

  getEmployee(uid) {
    this.commonService.getEmployee(this.cid, uid.value, res => {
      this.setUserInfo(res);
    },
      err => this.getUserError(err));
  }

  getUserError(err) {
    this.toastr.error(err);
  }

  setUserInfo(userDetails) {
    if (userDetails) {
      this.subjectsForm.patchValue({
        id: this.subject ? this.subject.id : 0,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        employeeUId: userDetails.uid,
        jobTitle: userDetails.title ? userDetails.title : '',
      });
    }
    else {
      this.subjectsForm.patchValue({
        id: 0,
        firstName: '',
        lastName: '',
        jobTitle: '',
        employeeUId: '',
        subjectRelationshipToOrganization:''
      });
    }
    if (this.uid) {
      this.uid.nativeElement.value = this.subjectsForm.value.employeeUId;
    }
  }

}
