import { Component, OnInit, ViewEncapsulation } from '@angular/core';

/**
 * How to use this component:
 * On any component that should be within the auth landing template, for any content you want in the right hand contianer
 * add the class ".right-hand-content" to the top parent, look at register component for example
 * 
 * If you have any modals in templates, specify them with the class ".templated-modal", also see register component
 * for example
 */
@Component({
  selector: 'app-main-landing-page',
  templateUrl: './main-landing-page.component.html',
  styleUrls: ['./main-landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainLandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
