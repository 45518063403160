import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "src/app/common.service";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-create-report-footer",
  templateUrl: "./create-report-footer.component.html",
  styleUrls: ["./create-report-footer.component.scss"]
})
export class CreateReportFooterComponent implements OnInit {
  @Output() OnBack = new EventEmitter();
  @Output() OnNext = new EventEmitter();
  @Output() ReportNav = new EventEmitter();
  @Input() isHideFooter;
  cid: any;
  enableBack: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
    });

    this.commonService.NotifyTabChange.subscribe(sub => {
      this.disableBackButton();
    });
  }
  showDisclaimer() {
    this.ReportNav.emit();
  }
  goBack() {
    this.OnBack.emit();
  }
  goNext() {
    this.OnNext.emit();
  }
  disableBackButton() {
    let res: any;
    if (this.deviceService.isMobile()) {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("mobileNavs")
      );
    } else {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("webNavs")
      );
    }
    this.enableBack = res.findIndex(x => x.className.includes("active")) !== 0;
  }
}
