import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-terms-and-policies",
  templateUrl: "./terms-and-policies.component.html",
  styleUrls: ["./terms-and-policies.component.scss"]
})
export class TermsAndPoliciesComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
  scrollContent() {
    document.getElementById("contactInfo").scrollIntoView({
      behavior: "smooth"
    });
  }
}
