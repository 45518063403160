import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppStateService } from 'src/app/appStateService';
import { CommonService } from 'src/app/common.service';
import { FaqComponent } from 'src/app/static/faq/faq.component';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {
  languageCdSubscription: Subscription;
  selectedLang: string;
  constructor(public appStateService: AppStateService, private route: ActivatedRoute,
    private translateService: TranslateService, private modalService: NgbModal) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.appStateService.languages = [{ languageName: 'English', languageCD: 'en-US' }];
      this.appStateService.language = this.appStateService.languages[0].languageCD;
      const languageCDSplit = this.appStateService.language.split("-")[0];
      this.translateService.use(languageCDSplit);
      this.translateService.get('Select').subscribe((text: string) => {
        this.appStateService.select = text;
      });
    });
  }

  faqModal() {
    this.modalService.open(FaqComponent, { size: "xl", backdrop: "static" });
  }
}
