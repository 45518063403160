import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { CommonService } from "../common.service";
import { AppStateService } from "../appStateService";
import * as moment from "moment-timezone";

@Component({
  selector: "app-additional-details",
  templateUrl: "./additional-details.component.html",
  styleUrls: ["./additional-details.component.scss"]
})
export class AdditionalDetailsComponent implements OnInit {
  @Input() categories: any;
  @Input() selectedCategory: any;
  @Input() questions: any;
  @Input() questionRules: [];
  @Output() selectedCategoryOutput: EventEmitter<any> = new EventEmitter();
  @Output() ruleQuestionValueChange: EventEmitter<any> = new EventEmitter();
  isDirty = false;
  orderedQuestions: Array<any>;
  searchText = "";
  cid: any;
  concern: string = null;
  isValidate = false;
  multiCss = {
    width: "300px",
    height: "57px"
  };
  constructor(
    public fb: FormBuilder,
    private commonService: CommonService,
    public appStateService: AppStateService
  ) { }

  ngOnInit() {
    this.commonService.AdditionalInfo = res => {
      return this.additionalInfoChange(res);
    };
    if (this.appStateService.additionalDetails) {
      if (this.appStateService.additionalDetails.questions) {
        this.questions = this.appStateService.additionalDetails.questions;
      }
      if (this.appStateService.additionalDetails.concern) {
        this.concern = this.appStateService.additionalDetails.concern;
      }
      if (this.appStateService.documents) {
        this.commonService.selectedFiles = this.appStateService.documents;
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === "questions" && this.questions != null) {
        this.questions.forEach(element => {
          if (element.questionType == "Checkbox List") {
            this.setCheckboxTypeQuestions(element);
          }
        });
      }
    }
  }

  setCheckboxTypeQuestions(ques) {
    var checkboxlist = [];
    ques.defaultValues.forEach(element => {
      var fileData = {};
      fileData['Name'] = element;
      fileData['Selected'] = false;
      checkboxlist.push(fileData)
    });
    ques['CheckListOptions'] = checkboxlist;

    var selectedItems = [];
    if (ques.answer) {
      let selectedData = ques.answer.split('*|*');
      selectedData.forEach(element => {
        var fileData = {};
        fileData['Name'] = element;
        fileData['Selected'] = true;
        selectedItems.push(fileData);
      });
    }
    ques['SelectedCheckListOptions'] = selectedItems;
  }

  setDropdown() {
    this.searchText = "";
  }

  additionalInfoChange(validate = null) {
    this.isValidate = validate;
    if (!validate) {
      return {
        rules: this.questionRules,
        questions: this.questions,
        subjects: !this.appStateService.hideSubject ? this.commonService.SubjectsInfo() : [],
        concern: this.concern,
        documents: this.commonService.selectedFiles
      };
    }
  }

  valueChange(id, value) {
    let optionQuest;
    this.questions.forEach(element => {
      if (element.questionID === id) {
        if (element.questionType == "Calendar") {
          element.answer = value && moment(value).format("MM/DD/YYYY") != "Invalid date" ? moment(value).format("MM/DD/YYYY") : "";
        }
        else {
          element.answer = value;
        }
        optionQuest = element.value !== "" ? element : null;
      }
    });
    this.isDirty = true;
    if (optionQuest && optionQuest.RuleExists) {
      this.ruleQuestionValueChange.emit(optionQuest);
    }
  }

  getChecked(value) {
    this.selectedCategory = value;
    this.selectedCategoryOutput.emit(value);
    document.getElementById("categoryDropdown").scroll(0, 0);
  }

  chkboxValueChange(id, value) {
    this.questions.forEach(element => {
      if (element.questionID === id) {
        element.answer = value;
      }
    });
  }

  outputData(ques, data) {
    this.chkboxValueChange(ques.questionID, data.map(x => x.Name).join('*|*'));
  }
}
