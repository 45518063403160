import { Component, OnInit } from "@angular/core";
import { AppStateService } from "../appStateService";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TermsAndPoliciesComponent } from "../static/terms-and-policies/terms-and-policies.component";

@Component({
  selector: "app-success-copy",
  templateUrl: "./success-copy.component.html",
  styleUrls: ["./success-copy.component.scss"]
})
export class SuccessCopyComponent implements OnInit {
  showToast: boolean;
  constructor(
    public appStateService: AppStateService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
  }

  print() {
    window.print();
  }
  copyText() {
    const result = document.getElementById("reportId").innerHTML;    
    const input_temp = document.createElement("input");
    input_temp.value = result.replace(/[^a-zA-Z؀-ۿ0-9-]+/g,'');
    document.body.appendChild(input_temp);
    input_temp.select();
    input_temp.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(input_temp);
    document.execCommand("copy");
  }

  termsPolicies() {
    this.modalService.open(TermsAndPoliciesComponent, {
      size: "xl"
    });
  }
 }
