import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeaderComponent } from "./common/header/header.component";
import { FooterComponent } from "./common/footer/footer.component";
import { ReportDisclaimerComponent } from "./report-disclaimer/report-disclaimer.component";
import { CreateReportComponent } from "./create-report/create-report.component";
import { FaqComponent } from "./static/faq/faq.component";
import { CreateReportFooterComponent } from "./common/create-report-footer/create-report-footer.component";
import { EUDisclaimerComponent } from "./static/eu-disclaimer/eu-disclaimer.component";
import { CategoryListComponent } from "./category-list/category-list.component";
import { TermsAndPoliciesComponent } from "./static/terms-and-policies/terms-and-policies.component";
import { AddSubjectsComponent } from "./add-subjects/add-subjects.component";
import { SubjectsComponent } from "./subjects/subjects.component";
import { GeneralSectionComponent } from "./general-section/general-section.component";
import { BeginReportComponent } from "./begin-report/begin-report.component";
import { CustomDialogComponent } from "./custom-dialog/custom-dialog.component";
import { FilterPipe } from "./filter.pipe";
import { BrowseLocationsModalComponent } from "./browse-locations-modal/browse-locations-modal.component";
import { GridFilterPipe } from "./grid-filter.pipe";
import { AdditionalDetailsComponent } from "./additional-details/additional-details.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ReportSummaryComponent } from "./report-summary/report-summary.component";
import { McrReportComponent } from "./mcr-report/mcr-report.component";
import { AppInitService } from "./app-init.service";
import { CommonService } from "./common.service";
import { CustomDialogService } from "./custom-dialog/custom-dialog.service";
import { SuccessCopyComponent } from "./success-copy/success-copy.component";
import { FollowUpReportComponent } from "./follow-up-report/follow-up-report.component";
import { HighlightSearch } from "./highlight.pipe";
import { DeviceDetectorModule } from "ngx-device-detector";
import { AuthGuard } from "./auth.guard";
import { RenrDisclaimerComponent } from './renr-disclaimer/renr-disclaimer.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CustomMultiselectComponent } from "./common/custom-multiselect/custom-multiselect.component";
import { UploadFilesComponent } from "./shared/upload-files/upload-files.component";
import { FollowUpLandingComponent } from "./landing-page/follow-up-landing/follow-up-landing.component";
import { FormComponent } from "./form/form/form.component";
import { FormHeaderComponent } from "./form/form-header/form-header.component";
import { FormFooterComponent } from "./form/form-footer/form-footer.component";
import { MainLandingPageComponent } from "./landing-page/main-landing-page/main-landing-page.component";
import { BeginReportLandingComponent } from "./landing-page/begin-report-landing/begin-report-landing.component";
import { ToastrModule } from "ngx-toastr";
import { BsDatepickerModule, BsDatepickerConfig, } from "ngx-bootstrap/datepicker";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({ 
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ReportDisclaimerComponent,
    CreateReportComponent,
    FaqComponent,
    CreateReportFooterComponent,
    EUDisclaimerComponent,
    CategoryListComponent,
    TermsAndPoliciesComponent,
    AddSubjectsComponent,
    SubjectsComponent,
    GeneralSectionComponent,
    BeginReportComponent,
    CustomDialogComponent,
    FilterPipe,
    BrowseLocationsModalComponent,
    GridFilterPipe,
    AdditionalDetailsComponent,
    ReportSummaryComponent,
    McrReportComponent,
    SuccessCopyComponent,
    FollowUpReportComponent,
    HighlightSearch,
    RenrDisclaimerComponent,
    FormComponent,
    FormHeaderComponent,
    FormFooterComponent,
    CustomMultiselectComponent,
    MainLandingPageComponent,
    BeginReportLandingComponent,
    FollowUpLandingComponent,
    UploadFilesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule,
    NgbModule,
    AngularMultiSelectModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    DeviceDetectorModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    AppInitService,
    CommonService,
    CustomDialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [AppInitService],
      multi: true
    },
  { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },

  ],
  entryComponents: [
    CustomDialogComponent,
    AddSubjectsComponent,
    BrowseLocationsModalComponent,
    EUDisclaimerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function init(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.load();
  };
}
export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-blue',
    dateInputFormat: 'MM-DD-YYYY',
    showWeekNumbers: false,
    customTodayClass:'custom-today-class'
  });
}
