import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppStateService } from "src/app/appStateService";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FaqComponent } from "src/app/static/faq/faq.component";
import { TermsAndPoliciesComponent } from "src/app/static/terms-and-policies/terms-and-policies.component";
import { EUDisclaimerComponent } from "src/app/static/eu-disclaimer/eu-disclaimer.component";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/common.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  selectedLang: string;
  public isHeaderShow: boolean = false;
  cid: any;
  customerName: string;
  isLanding = false;
  lang: [] = [];
  languageCdSubscription: Subscription;
  isShowFAQs: boolean = false;
  languageCD: string;
  customerId: string;
  url: string;
  constructor(
    public appStateService: AppStateService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    translateService.setDefaultLang("en");
    router.events.subscribe((event: any) => {
      if (event.url) {
        if (
          event.url === "/" ||
          event.url === "/MCR" ||
          event.url === "/followUp"
        ) {
          this.isHeaderShow = false;
          this.url = event.url;
        } else {
          this.isHeaderShow = true;
          this.url = event.url;
          this.GetMCRFAQs(this.languageCD);
        }
        if (
          event.url === "/followUp" &&
          (event.url !== "/" && event.url !== "/MCR")
        ) {
          this.isLanding = true;
          this.clearDetails();
        } else if (event.url === "/MCR") {
          this.isLanding = false;
          this.clearDetails();
        }
      }
    });
  }

  ngOnInit() {
    this.languageCdSubscription = this.appStateService.languageCd
      .subscribe(sub => {
        this.onLangSelect(sub);
      });
    if (!this.lang || this.lang.length == 0) {
      this.getDefaultLanguages();
    }
    else {
      this.setDefaultLanguage();
    }
  }

  ngOnDestroy() {
    this.languageCdSubscription.unsubscribe();
  }

  onLangSelect(lang) {
    this.languageCD = lang.languageCD;
    this.GetMCRFAQs(this.languageCD);
    const languageCDSplit = lang.languageCD.split("-")[0];
    const langCD = languageCDSplit.toUpperCase();
    if (langCD !== this.selectedLang) {
      this.appStateService.getFolder(`/assets/i18n/${languageCDSplit}.json`).subscribe(response => {
        if (response) {
          this.setLanguageSelected(lang, langCD, languageCDSplit);
        }
        else {
          this.onLangSelect(this.appStateService.languages.find(l => l.languageCD == 'en-US'));
        }
      });
    }
    // sessionStorage.setItem("language", this.selectedLang);
    // this.changeDirection();
    // document.getElementById("navigateDropdown").scroll(0, 0);
  }

  setLanguageSelected(lang, langCD, languageCDSplit) {
    this.selectedLang = langCD;
    this.appStateService.language = lang.languageCD;
    this.appStateService.languageSub.next(lang.languageCD);
    this.translateService.use(languageCDSplit);
    if (this.commonService.fetchCountries) {
      this.commonService.fetchCountries();
    }
    sessionStorage.setItem("language", this.selectedLang);
    this.changeDirection();
    document.getElementById("navigateDropdown").scroll(0, 0);
  }

  setDefaultLanguage() {
    if (!this.appStateService.languages || this.appStateService.languages.length == 0) {
      this.appStateService.languages = this.lang;
      this.appStateService.language = this.appStateService.language
        ? this.appStateService.language
        : this.appStateService.languages[0].languageCD;
    }
    this.selectedLang = this.appStateService.language
      .split("-")[0]
      .toUpperCase();
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
      this.customerName = this.appStateService.customerName;
    });
  }

  openDisclaimer() {
    this.modalService.open(EUDisclaimerComponent, {
      size: "lg",
      centered: true,
      backdrop: "static"
    });
  }

  faqModal() {
    this.modalService.open(FaqComponent, { size: "xl", backdrop: "static" });
  }

  termsPolicies() {
    this.modalService.open(TermsAndPoliciesComponent, {
      size: "xl"
    });
  }
  disableScroll() {
    document.body.style.overflow = "hidden";
  }
  setScroll() {
    document.body.style.overflow = "auto";
  }
  changeDirection() {
    this.translateService.get('Select').subscribe((text: string) => {
      this.appStateService.select = text;
    });
    if (this.selectedLang == "AR") {
      document.body.className = "change-direction";
      document.body.style.direction = "rtl";
    } else {
      document.body.className = "ltr";
      document.body.style.direction = "ltr";
    }
  }

  logOff() {
    this.clearDetails();
    sessionStorage.clear();
    this.router.navigate(["/MCR"]);
  }

  private clearDetails() {
    if (this.appStateService.languageSubscription) {
      this.appStateService.languageSubscription.unsubscribe();
    }
    if (this.appStateService.createReportLanguageSubscription) {
      this.appStateService.createReportLanguageSubscription.unsubscribe();
    }
    this.appStateService.categories = null;
    this.appStateService.countries = null;
    this.appStateService.mcrLogo = null;
    this.appStateService.customerName = null;
    this.appStateService.languages = this.lang;
    this.appStateService.mcrCallerType = null;
    this.appStateService.mcrCommTool = null;
    this.appStateService.reportId = null;
    this.appStateService.clearReportDetails();
    if (this.appStateService.languages && this.appStateService.languages.length > 0) {
      this.onLangSelect(this.appStateService.languages[0]);
    }
  }

  collapse() {
    var res = document.getElementById("collapsibleNavbar");
    res.classList.remove("show");
    var res = document.getElementById("toggleButton");
    res.classList.add("collapsed");
    this.setScroll();
  }

  createAudit(form, url) {
    this.commonService.createAudit(null, form, url);
  }

  getDefaultLanguages() {
    this.commonService.getDefaultLanguages(res => {
      this.lang = res.responseData;
      this.setDefaultLanguage();
    },
      err => {
        console.log("Error while fetching default Languages" + err);
      });
  }

  GetMCRFAQs(language) {
    if (
      this.url === "/" ||
      this.url === "/MCR" ||
      this.url === "/followUp"
    ) {
      this.isShowFAQs = false;
    }
    else {
      this.customerId = this.appStateService.reportId != null ? this.appStateService.reportId.split("-")[1] : "";
      if (this.customerId != "" && this.appStateService) {
        this.cid = this.customerId;
        language = language == null ? "en-US" : language;
      }
      else {
        this.route.queryParams.subscribe(params => {
          this.cid = params.cid
        });
        language = language == undefined ? "en-US" : language;
      }
      if (this.cid != null && this.cid != undefined && this.cid != "" && this.cid.toUpperCase() != "TGTHR") {
        this.commonService.getMcrConfigDetailsByConfigId(
          this.cid,
          language,
          res => {
            if (res) {
              if (
                this.url === "/" ||
                this.url === "/MCR" ||
                this.url === "/followUp"
              ) {
                this.isShowFAQs = false;
              }
              else {
                this.isShowFAQs = res.showFAQs;
              }
            }
          },
          err => {
            console.log("Error while fetching MCR Config value" + err);
          }
        );
      }
    }
  }
}
