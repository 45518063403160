import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDialogComponent } from './custom-dialog.component';
import { SafeHtml } from '@angular/platform-browser';

@Injectable()
export class CustomDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string | SafeHtml,
    btnOkAction: any,
    btnCancelAction: any,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm',
    showCancelBtn: boolean = true,
    showOkBtn: boolean = true) {
    const modalRef = this.modalService.open(CustomDialogComponent, { size: dialogSize, backdrop: "static", keyboard: false, });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.isAlert = btnCancelAction === undefined ? true : false;
    modalRef.componentInstance.showCancelBtn = showCancelBtn;
    modalRef.componentInstance.showOkBtn = showOkBtn;
    modalRef.result.then((confirmed) =>
      confirmed ? btnOkAction() : btnCancelAction())
  }

  closeDialogService() {
    this.modalService.dismissAll();
  }
}
