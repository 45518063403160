import { Injectable } from "@angular/core";
import { BehaviorSubject, of, Subject, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AppStateService {
  createReportLanguageSubscription: Subscription;
  languageSubscription: Subscription;
  categories: any;
  countries: any;
  languages: any;
  mcrCallerType: any;
  mcrCommTool: any;
  requireLocation: boolean;
  lockLocation: boolean;
  enviromentData;
  languageSub = new Subject<any>();
  language: any;
  customerId: string;
  customerName: string;
  countryId: number;
  generalDetails: any;
  additionalDetails: any;
  documents: { file: File, fileName:string, isSuccess:boolean, description: string, uniqueIdentifier?: string; errorMessage: string }[] = [];
  selectedCategory: any;
  isSubmitted: boolean;
  reportId: string;
  mcrLogo = null;
  languageCd = new Subject<any>();
  showAnonymous = true;
  private showAnonymousValueSubject = new BehaviorSubject<boolean>(true); 
  showAnonymousValue$ = this.showAnonymousValueSubject.asObservable(); 
  subjectTypes: any;
  subjectRelationship: any;
  showSubjectRelationship: boolean;
  select: string = "Select";
  mcrWhatsNext: any;
  detailMin: any;
  detailMax: any;
  ConcernStatement: any;
  hasBounds: boolean;
  callbackDate: any;
  hideCountry: boolean = true;
  AllowFollowUp: boolean = true;
  RequireConsentStatement: boolean;
  ConsentStatement: string;
  ScrollAccept: boolean;
  hideSubject: boolean;
  hideConcern: boolean;
  mcrValidity: any;
  HideEmployeeIDLookup: string;
  MCRDisclaimerPageHeader: string;
  showFirstName: boolean;
  requireFirstName: boolean;
  showLastName: boolean;
  requireLastName: boolean;
  showJobTitle: boolean;
  requireJobTitle: boolean;
  showAddress: boolean;
  requireAddress: boolean;
  showCity: boolean;
  requireCity: boolean;
  showState: boolean;
  requireState: boolean;
  showPostalCode: boolean;
  requirePostalCode: boolean;
  showPhone: boolean;
  requirePhone: boolean;
  showEmail: boolean;
  requireEmail: boolean;
  EuDisclaimer: boolean;
  EuDisclaimerStatement: any;
  ShowSubjectNotes: boolean = true;
  IsCurrentCustomerMCRLite: boolean = false;
  HideFileUpload: boolean;
  HideFirstReportQuestion: boolean;
  AnonymousReportingHelpText: string;
  McrChatbotEnabled: boolean;
  AllowMaskedEmail: boolean = true;

  constructor(private http: HttpClient) { }

  set showAnonymousValue(value: boolean) {
    this.showAnonymousValueSubject.next(value);
  }

  get showAnonymousValue(): boolean {
    return this.showAnonymousValueSubject.getValue();
  }


  getBaseUrl() {
    const apiString =
      this.enviromentData.deploymentMode.toLowerCase() === "dev"
        ? "/api/"
        : "/webservice/api/";
    if (this.enviromentData.https) {
      return (
        "https://" +
        this.enviromentData.server +
        ":" +
        this.enviromentData.port +
        apiString
      );
    } else {
      return (
        "http://" +
        this.enviromentData.server +
        ":" +
        this.enviromentData.port +
        apiString
      );
    }
  }

  clearReportDetails() {
    this.selectedCategory = null;
    this.additionalDetails = null;
    this.generalDetails = null;
  }


  getFolder(folderPath: string) {
    return this.http
      .get(`${folderPath}`, { observe: 'response', responseType: 'json' })
      .pipe(
        map(response => {
          return true;
        })
        , catchError(r => { return of(false) })
      );
  }
}
