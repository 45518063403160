import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter"
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, filter: {[key: string]: any }): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    if (filter) {
      return items.filter(item => {
        let MatchingField = Object.keys(filter)
                                     .find(key => item[key] && item[key].toLocaleLowerCase()
                                     .includes(filter[key].toLocaleLowerCase()));

        return MatchingField; // true if matches any field
    });
    } else {
      return items.filter(item => {
        return item
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase());
      });
    }
  }
}
