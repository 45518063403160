import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppStateService } from "src/app/appStateService";
import { CommonService } from "../../common.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"]
})
export class FaqComponent implements OnInit {
  proxyForm: boolean = false;
  defaultFaq: boolean = false;
  FaqsData = [];

  constructor(public activeModal: NgbActiveModal, public appStateService: AppStateService, private commonService: CommonService, private toastr: ToastrService) { }
  ngOnInit() {
    if (this.appStateService.reportId != null) {
      this.appStateService.customerId = this.appStateService.reportId.split("-")[1];
    }

    if (this.appStateService && this.appStateService.customerId && this.appStateService.customerId.toUpperCase() == "TGTHR") {
      this.proxyForm = true;
    }
    else {
      if (this.appStateService.customerId == "" || this.appStateService.customerId == undefined || this.appStateService.customerId == null) {
        this.defaultFaq = true;
      }
      else {
        this.GetMCRFAQs();
      }
    }
  }

  GetMCRFAQs() {
    this.commonService.getMcrConfigDetailsByConfigId(
      this.appStateService.customerId,
      this.appStateService.language,
      res => {
        if (res.mcrFaq) {
          let mcrFaqs = JSON.parse(res.mcrFaq);
          this.FaqsData = [];
          mcrFaqs.forEach(x =>
            this.FaqsData.push({ 'Question': x.Question, 'Answer': x.Answer })
          )
          //console.log(this.FaqsData);
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }
}
