import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.scss']
})
export class FormFooterComponent implements OnInit {
  @Output() OnBack = new EventEmitter();
  @Output() OnNext = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  goNext() {
    this.OnNext.emit();
  }
  goBack() {
    this.OnBack.emit();
  }
}
