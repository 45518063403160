import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-report-disclaimer",
  templateUrl: "./report-disclaimer.component.html",
  styleUrls: ["./report-disclaimer.component.scss"]
})
export class ReportDisclaimerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
