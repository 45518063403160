import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { AppStateService } from "./appStateService";
import { CommonService } from './common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private appStateService: AppStateService,
    private commonService : CommonService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      (state.url === "/success" && this.appStateService.isSubmitted) ||
      (state.url === "/followUp-report" && this.appStateService.reportId)
    ) {
      return true;
    } else {
      this.router.navigate(["/MCR"]);
      return true;
    }
  }
}
