import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from "@angular/core";
@Component({
  selector: "app-custom-multiselect",
  templateUrl: "./custom-multiselect.component.html",
  styleUrls: ["./custom-multiselect.component.scss"]
})
export class CustomMultiselectComponent implements OnInit {
  @Input() data: any;
  @Input() type: string;
  @Input() selectTemplate: TemplateRef<any>;
  @Input() selectedItems: any;
  @Input() compareWith: string;
  @Input() compareDisabledWith: string = '';
  @Output() selectedValues: EventEmitter<any> = new EventEmitter();
  @Output() doubleClick: EventEmitter<any> = new EventEmitter();
  @Output() DragListDropped: EventEmitter<any> = new EventEmitter();
  @Input() classValue: {};
  @Input() sortable: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() dragable: boolean = false;
  @Input() draggable: boolean = false;
  @Input() hideSelectNone: boolean = false;
  @Input() isFromReports: boolean = false;

  activeElement: any;
  value: any[];
  newList: any[];

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    if (this.data && this.data.length) {
      this.data.map(member => {
        member.selected = false;
      });
      if (this.type == 'checkbox') {
        this.newList = this.assignData(this.data);
      } else {
        this.newList = Object.assign([], this.data);
      }
      if (this.selectedItems && this.selectedItems.length) {
        const selected: any[] = [];
        this.newList.map(member => {
          member.selected = (this.selectedItems.filter
            (selMem => selMem[this.compareWith] == member[this.compareWith])).length ? true : false;
          member.selected ? selected.push(member) : '';
        });
        this.value = selected.slice(0);
      } else {
        this.newList.map(member => member.selected = false);
      }
    }
    else {
      this.newList = null;
    }
  }

  assignData(data) {
    return JSON.parse(JSON.stringify(data));
  }


  change(member = "") {
    if (member) {
      this.newList.map(item => {
        if (item[this.compareWith] !== member[this.compareWith]) {
          item.selected = false;
        }
      });
      this.selectedItems[0] = this.assignData(member);
      delete this.selectedItems[0].selected;
      this.selectedValues.emit(this.selectedItems);
      return;
    }
    const checkedOptions = this.assignData(this.value);
    checkedOptions.map(option => {
      delete option.selected;
    });
    this.selectedItems = checkedOptions;
    this.selectedValues.emit(this.selectedItems);
  }

  changemulticheck(member = "") {
    if (member) {
      this.newList.map(item => {
        if (item[this.compareWith] !== member[this.compareWith]) {
          item.selected = false;
        }
      });
      this.selectedItems[0] = this.assignData(member);
      delete this.selectedItems[0].selected;
      this.selectedValues.emit(this.selectedItems);
      return;
    }
    const checkedOptions = this.assignData(this.newList.filter(x => x.selected));
    checkedOptions.map(option => {
      delete option.selected;
    });
    this.selectedItems = checkedOptions;
    this.selectedValues.emit(this.selectedItems);
  }
  doubleClickEvent(e) {
    let member = this.newList[e.currentTarget.selectedIndex];
    this.doubleClick.emit(member);
  }

  selectAll() {
    this.newList.map(member => {
      member.selected = true;
    });
    const checkedOptions = this.assignData(this.newList.filter(x => x.selected));
    checkedOptions.map(option => {
      delete option.selected;
    });
    this.selectedItems = checkedOptions;
    this.selectedValues.emit(this.selectedItems);
  }

  unselectAll() {
    // Check if default selected items exist when type is checkbox.
    let checkboxDefaultItems = false;
    checkboxDefaultItems = this.type == 'checkbox' && this.compareDisabledWith != undefined && this.compareDisabledWith.length > 0;
    if (checkboxDefaultItems) {
      this.newList.map(member => {
        if (!member[this.compareDisabledWith]) {
          member.selected = false;
        }
      });
    }
    else {
      this.newList.map(member => {
        member.selected = false;
      });
    }
    const checkedOptions = this.assignData(this.newList.filter(x => x.selected));
    checkedOptions.map(option => {
      delete option.selected;
    });
    this.selectedItems = checkedOptions;
    this.selectedValues.emit(this.selectedItems);
  }
}
