import { Component, OnInit, ViewChild, ElementRef, DebugElement } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AppStateService } from "../appStateService";
import { CommonService } from "../common.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FaqComponent } from "../static/faq/faq.component";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { TermsAndPoliciesComponent } from "src/app/static/terms-and-policies/terms-and-policies.component";
import { CustomDialogService } from "../custom-dialog/custom-dialog.service";
import { AppConstants } from "../shared/app-constants";

@Component({
  selector: "app-begin-report",
  templateUrl: "./begin-report.component.html",
  styleUrls: ["./begin-report.component.scss"]
})
export class BeginReportComponent implements OnInit {
  countries = [];
  searchText: string;
  selectedCountry: any;
  reportDisclaimer: SafeHtml;
  mcrDisclaimerPageHeader: SafeHtml;
  mcrInitDisclaimer: any;
  cid: any;
  createReport: boolean;
  mcrLogoImageurl: any;
  mcrLogoBgImageurl: any;
  mcrConsentStatement: boolean;
  isAcknowledged: boolean = false;
  isChcekedAcknowledged: boolean = false;
  isOpenTermsandConditionPopup: boolean = false;
  scrollAndAccept: any;
  acceptedTermsAndCondition: any = false;
  acceptedCheckBoxValue: any = false;
  isAckEnabled: boolean = false;
  isShowFaqs: boolean;
  isLimitExceeded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customDialogService: CustomDialogService,
    private commonService: CommonService,
    private sanitized: DomSanitizer,
    public appStateService: AppStateService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.appStateService.languageSubscription = this.appStateService.languageSub
      .subscribe(sub => {
        this.GetReportDisclaimer();
      });
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
      this.createReport = false;
      if (!this.appStateService.language) {
        this.commonService.getLanguagesByCustomer(this.cid, res => {
          this.translateService.use(this.appStateService.language.split("-")[0]);
          this.getMCRProprties();
        }, err => {
          this.toastr.error(err.message);
        }, cb => {
          this.toastr.error("No MCR Configs found for this customer.");
          this.router.navigate(["/MCR"]);
        });
      }
      else {
        this.getMCRProprties();
      }
    });
    this.commonService.fetchCountries = res => {
      return this.getCountries();
    };

    const uuid = this.commonService.generateGUID();
    sessionStorage.setItem('UUID', uuid.toString());
  }

  getMCRProprties() {
    this.appStateService.clearReportDetails();
    this.reportDisclaimer = "";
    this.GetReportDisclaimer();
    this.getCountries();
    this.convertDirection();
  }

  getCountries() {
    this.commonService.getCountries(
      '',
      this.appStateService.language,
      res => {
        this.countries = res;
        this.appStateService.countries = this.countries;
        this.selectedCountry = this.countries.find(
          x => x.countryID == 202
        );
        // this.selectedCountry = this.countries[0];
        if (this.selectedCountry) {
          this.appStateService.countryId = +this.selectedCountry.countryID;
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  GetReportDisclaimer() {
    this.commonService.getMcrConfigDetailsByConfigId(
      this.cid,
      this.appStateService.language,
      res => {
        if (res) {
          this.mcrConfigDetailsSuccessCallback(res);
          if (this.appStateService.McrChatbotEnabled) {
            this.loadAdaChatbot();
          }
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  mcrConfigDetailsSuccessCallback(res: any) {
    this.appStateService.mcrCallerType = res.mcR_CallerType
      ? res.mcR_CallerType.split("\n").filter(v => v !== "" && v !== "\r")
      : null;
    this.appStateService.mcrCommTool = res.mcR_CommTool
      ? res.mcR_CommTool.split("\n").filter(v => v !== "" && v !== "\r")
      : null;
    this.appStateService.requireLocation =
      res.requireLocation === "N" && this.cid !== 'CYH' ? false : true;
    this.appStateService.lockLocation =
      res.lockLocations === "N" || this.cid === 'CYH' || this.cid === 'BCRI' || this.cid === 'TEK' ? false : true;
    this.appStateService.HideEmployeeIDLookup = res.hideEmployeeIDLookup;
    this.appStateService.hideCountry =
      res.hideCountry === "Y" ? true : false;
    this.appStateService.hideSubject =
      res.hideSubject === "Y" ? true : false;
    this.appStateService.hideConcern = res.hideConcern === "Y" ? true : false;
    this.appStateService.ConcernStatement = res.concernStatement != null ? this.sanitized.bypassSecurityTrustHtml(
      res.concernStatement
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
    ) : 'Please describe your concern or question';
    this.appStateService.detailMin = (res.detailMin > 0) ? res.detailMin : 0;
    this.appStateService.detailMax = (res.detailMax > 0) ? res.detailMax : 0;
    this.appStateService.hasBounds = res.detailMin > 0 && res.detailMax > 0;
    this.appStateService.AllowFollowUp = res.allowFollowUps;
    this.appStateService.RequireConsentStatement = res.requireConsentStatement;
    this.appStateService.ConsentStatement = res.consentStatement;
    this.appStateService.ScrollAccept = res.scrollAccept;
    this.isAcknowledged = res.requireConsentStatement ? false : true;
    this.appStateService.EuDisclaimer = res.euDisclaimer;
    this.appStateService.EuDisclaimerStatement = this.sanitized.bypassSecurityTrustHtml(
      res.euDisclaimerStatement
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
    );
    this.isShowFaqs = res.showFAQs;
    this.appStateService.ShowSubjectNotes = res.showSubjectNotes;
    this.appStateService.IsCurrentCustomerMCRLite = (res.mcrTier == AppConstants.MCRLiteCustomer) ? true : false
    this.appStateService.HideFileUpload = res.hideFileUpload;
    this.mcrDisclaimerPageHeader = res.mcrDisclaimerPageHeader
      ? this.sanitized.bypassSecurityTrustHtml(
        res.mcrDisclaimerPageHeader
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      )
      : null;

    this.reportDisclaimer = res.mcrInitDisclaimer
      ? this.sanitized.bypassSecurityTrustHtml(
        res.mcrInitDisclaimer
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      )
      : null;

    this.appStateService.mcrWhatsNext = res.mcrWhatsNext
      ? this.sanitized.bypassSecurityTrustHtml(
        res.mcrWhatsNext
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      )
      : null;

    if (res.mcrLogo) {
      this.setmcrLogoImgFromBinary(res.mcrLogo);
    } else {
      this.mcrLogoImageurl = null;
      this.appStateService.mcrLogo = null;
    }
    if (res.mcrLogoBGImg) {
      this.setmcrLogoBgImgFromBinary(res.mcrLogoBGImg);
    } else {
      this.mcrLogoBgImageurl = null;
    }

    this.appStateService.showFirstName = res.showFirstName;
    this.appStateService.requireFirstName = res.requireFirstName;
    this.appStateService.showLastName = res.showLastName;
    this.appStateService.requireLastName = res.requireLastName;
    this.appStateService.showJobTitle = res.showJobTitle;
    this.appStateService.requireJobTitle = res.requireJobTitle;
    this.appStateService.showAddress = res.showAddress;
    this.appStateService.requireAddress = res.requireAddress;
    this.appStateService.showCity = res.showCity;
    this.appStateService.requireCity = res.requireCity;
    this.appStateService.showState = res.showState;
    this.appStateService.requireState = res.requireState;
    this.appStateService.showPostalCode = res.showPostalCode;
    this.appStateService.requirePostalCode = res.requirePostalCode;
    this.appStateService.showPhone = res.showPhone;
    this.appStateService.requirePhone = res.requirePhone;
    this.appStateService.showEmail = res.showEmail;
    this.appStateService.requireEmail = res.requireEmail;
    this.appStateService.HideFirstReportQuestion = res.hideFirstReportQuestion;
    this.appStateService.AnonymousReportingHelpText = res.anonymousReportingHelpText;
    this.appStateService.McrChatbotEnabled = res.mcrChatbotEnabled;
    this.appStateService.AllowMaskedEmail = res.allowMaskedEmail;
  }

  setmcrLogoImgFromBinary(base64String) {
    this.mcrLogoImageurl = this.sanitized.bypassSecurityTrustUrl(
      "data:image/png;base64, " + base64String
    );
    this.appStateService.mcrLogo = this.mcrLogoImageurl;
  }

  setmcrLogoBgImgFromBinary(base64String) {
    let self = this;
    var img = new Image();
    img.src = "data:image/png;base64," + base64String;
    var srcEncoded;
    img.onload = function (el: any) {
      var elem = document.createElement("canvas");
      var width = img.width;
      var height = img.height;
      var MAX_WIDTH = 1170;
      var MAX_HEIGHT = 150;
      if (width > MAX_WIDTH && height > MAX_HEIGHT) {
        var ratioX = +(MAX_WIDTH / width);
        var ratioY = +(MAX_HEIGHT / height);
        //Figure out which is smaller, as this will cause a greater reduction in size overall
        if (ratioX < ratioY) {
          elem.width = Math.round(width * ratioX);
          elem.height = Math.round(height * ratioX);
        } else {
          elem.width = Math.round(width * ratioY);
          elem.height = Math.round(height * ratioY);
        }
      } else if (width > MAX_WIDTH) {
        var ratio = +(MAX_WIDTH / width).toFixed(2);
        elem.width = Math.round(width * ratio); //Should be MAX_WIDTH
        elem.height = Math.round(height * ratio);
      } else if (height > MAX_HEIGHT) {
        var ratio = +(MAX_HEIGHT / height).toFixed(2);
        elem.width = Math.round(width * ratio);
        elem.height = Math.round(height * ratio); //Should be MAX_HEIGHT
      } else {
        elem.width = width;
        elem.height = height;
      }
      var ctx = elem.getContext("2d");
      ctx.drawImage(el.target, 0, 0, elem.width, elem.height);
      srcEncoded = ctx.canvas.toDataURL();
      self.mcrLogoBgImageurl = self.sanitized.bypassSecurityTrustUrl(
        srcEncoded);
    };

  }

  countryChange(value) {
    this.searchText = "";
    if (this.selectedCountry.countryName !== value.countryName) {
      if (
        this.appStateService.selectedCategory ||
        this.appStateService.generalDetails ||
        this.appStateService.additionalDetails
      ) {
        this.customDialogService.confirm(
          "Sure",
          "ConfirmLocationChange",
          () => {
            this.selectedCountry = value;
            this.appStateService.countryId = +value.countryID;
            this.appStateService.categories = null;
            this.appStateService.clearReportDetails();
          },
          () => {
            return;
          },
          "Proceed",
          "Cancel"
        );
      } else {
        this.selectedCountry = value;
        this.appStateService.countryId = +value.countryID;
        this.appStateService.categories = null;
        this.appStateService.clearReportDetails();
      }
    }
    document.getElementById("dropdownScroll").scroll(0, 0);
  }

  beginReport(isNewReport) {
    this.commonService.GetIssuesCount(this.cid,
      res => {
        if (res >= AppConstants.MCRReportMaxLimit) {
          this.isLimitExceeded = true;
        } 
        else {
          this.isLimitExceeded = false;
          if (isNewReport) {
            this.appStateService.clearReportDetails();
          }
          this.createReport = true;
          this.commonService.createAudit(
            this.cid,
            "New Report - Key Category",
            "/report"
          );
        }
      },
      err => this.toastr.error("Error while fetching issue count")
    );
  }

  onReportNav() {
    this.createReport = false;
    this.commonService.createAudit(
      this.cid,
      "New Report - Disclaimer",
      "/report"
    );
  }

  openFaqModal() {
    this.modalService.open(FaqComponent, { size: "xl", backdrop: "static" });
  }
  convertDirection() {
    if (this.appStateService.language == "ar-AE") {
      document.body.className = "change-direction";
      document.body.style.direction = "rtl";
    } else {
      document.body.className = "ltr";
      document.body.style.direction = "ltr";
    }
  }
  getScrollTop() {
    this.searchText = "";
    document.getElementById("dropdownScroll").scrollTop = 0;
  }
  fixDropdown() {
    document.getElementById('dropdownScroll').style.top = "100%";
  }

  termsPolicies() {

    if (this.appStateService.RequireConsentStatement && this.appStateService.ScrollAccept) {
      this.customDialogService.confirm(
        "TermsandConditions",
        this.appStateService.ConsentStatement,
        () => {

          if (!this.isAcknowledged) {
            this.isAcknowledged = !this.isAcknowledged;
          }
          this.isAckEnabled = true;
        },
        () => {
          this.isAcknowledged = false;
          this.isAckEnabled = false;
        },
        "Accept",
        "Cancel",
        "lg", true, true
      );

    }
    else {
      this.customDialogService.confirm(
        "TermsandConditions",
        this.appStateService.ConsentStatement,
        () => {
          this.isAcknowledged = !this.isAcknowledged;
        },
        () => {
          this.isAcknowledged = false;
        },
        "",
        "Close",
        "lg", true, false
      );

    }
  }

  agreeTandC(e) {

    this.isAcknowledged = !this.isAcknowledged;
    if (e.target.checked) {
      if (this.appStateService.RequireConsentStatement && this.appStateService.ScrollAccept && !this.isAckEnabled) {
        this.customDialogService.confirm(
          "TermsandConditions",
          this.appStateService.ConsentStatement,
          () => {
            this.isAckEnabled = true;
          },
          () => {
            this.isAcknowledged = false;
            this.isAckEnabled = false;
          },
          "Accept",
          "Cancel",
          "lg", true, true
        );

      }
    }

  }

  scrollToTop(): void {
    setTimeout(() => {
      const element = document.querySelector('.modal-header');
      this.scrollTo(element);
    }, 10)

  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView();
    }
  }

  loadAdaChatbot() {
    // add ada chatbot script
    const script = document.createElement('script');
    script.setAttribute('id', '__ada')
    script.setAttribute('data-handle', 'ethico')
    script.setAttribute('src', 'https://static.ada.support/embed2.js')
    document.head.appendChild(script);
  }

}

