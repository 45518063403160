import { Component, OnInit, Input } from "@angular/core";
import { TermsAndPoliciesComponent } from "src/app/static/terms-and-policies/terms-and-policies.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  @Input() public isCopyRights: boolean = false;
  @Input() public loginFooter: boolean = false;
  @Input() public showCenter: boolean = false;
  @Input() public color: string = 'black';
  @Input() public shouldIncludeTOC: boolean = false;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}
  termsPolicies() {
    this.modalService.open(TermsAndPoliciesComponent, {
      size: "xl"
    });
  }
}
