import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDialogService } from 'src/app/custom-dialog/custom-dialog.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppStateService } from '../../../app/appStateService';
import { CommonService } from 'src/app/common.service';
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { BrowseLocationsModalComponent } from "../../browse-locations-modal/browse-locations-modal.component";
import { debounceTime, map, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subscription, Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  reportedByForm: any;
  impactedPartyForm: any;
  appConstants: any;
  sameasForm: any;
  locationForm: any;
  userForm: any;
  selectedCountry: any;
  typeAheadData: any;
  cid: any;
  locations: any = [];
  successSubmit: boolean = false;
  selectedFiles: { file: File, description: string }[] = [];
  uploadedFilesSize = 0;
  maxfileSize = 50000000;
  maxSize = 100000000;
  isLoading = false;
  disableUpload = false;
  @ViewChildren("typeaheadInstance") typeaheadInstance: any;
  @ViewChild("uid", { static: false }) uid: ElementRef;
  @ViewChild("ruid", { static: false }) ruid: ElementRef;
  @ViewChild("iuid", { static: false }) iuid: ElementRef;
  today = new Date();
  countries = [];
  unique = (value, index, self) => {
    if (value !== "" && value !== " " && value !== null) {
      return self.indexOf(value) === index;
    }
  };
  isUserDetailsRequired: any = false;
  detailsForm: any;
  selectedValidity: any;
  selectedCategory: any = '';
  masterCategories: any;
  categoryNotes: string = '';
  subCategories: any;
  uidValue: any = '';
  ruidValue: any = '';
  iuidValue: any = '';
  constructor(
    private dialogService: CustomDialogService,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    public appStateService: AppStateService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.appConstants = AppConstants;
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
      this.selectedCountry = "";
      this.locationForm = this.fb.group({
        locationID: [0],
        nameID: [""],
        name: [""],
        address: [""],
        city: [""],
        state: [""],
        country: [""],
        postalCode: [""],
        level_1: [""],
        level_2: [""],
        level_3: [""],
        level_4: [""],
        level_5: [""],
        level_6: [""],
        level_7: [""],
        level_8: [""],
      });
      this.userForm = this.fb.group({
        id: new FormControl(0),
        firstName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
        lastName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
        jobTitle: [""],
        type: ["Submitter"],
        phone: [""],
        email: [
          "",
          [
            Validators.pattern(
              AppConstants.EmailPattern
            )
          ]
        ],
        employeeUId: ["", [Validators.required, Validators.maxLength(8), Validators.minLength(8)]],
        subjectRelationshipToOrganization: [""]
      });
      this.reportedByForm = this.fb.group({
        firstName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
        lastName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
        relationship: ["", [Validators.required]],
        phone: [""],
        email: [
          "",
          [
            Validators.pattern(
              AppConstants.EmailPattern
            )
          ]
        ],
        employeeUId: ["", [Validators.maxLength(8), Validators.minLength(8)]],
        subjectRelationshipToOrganization: [""]
      });
      this.impactedPartyForm = this.fb.group({
        id: new FormControl(0),
        firstName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
        lastName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
        jobTitle: ["", [Validators.required]],
        title: [""],
        type: ["Impacted Party"],
        phone: [""],
        email: ["", [
          Validators.pattern(AppConstants.EmailPattern)
        ]],
        employeeUId: ["", [Validators.required, Validators.maxLength(8), Validators.minLength(8)]],
        subjectRelationshipToOrganization: [""]
      });
      this.sameasForm = this.fb.group({
        isImpactedPartyDiff: ["", Validators.required]
      });
      this.detailsForm = this.fb.group({
        masterCategory: ["", [Validators.required]],
        subCategory: ["", this.subCategories ? Validators.required : []],//to be clarified
        incidentDate: ["", [Validators.required]],
        incidentDescription: ["", [Validators.required]],
        investigationOutcome: ["", [Validators.required]],
        investigationSummary: ["", [Validators.required]]
      });
      this.LoadDefaultData();
    });
    this.getLocations();
  }


  LoadDefaultData() {
    this.isLoading = true;
    this.commonService.GetMasterCategories(this.appStateService.customerId, this.appStateService.language,
      res => {
        this.masterCategories = res.responseData;
        this.isLoading = false;
      },
      err => {
        this.toastr.error("Error fetching Categories");
        this.isLoading = false;
      });
  }

  changeCategory(category) {
    if (this.selectedCategory != category) {

      if (!category.categoryID) {
        this.selectedCategory = '';
        this.subCategories = [];
        this.categoryNotes = '';
        this.detailsForm.patchValue({
          masterCategory: ''
        });
        return;
      }
      this.detailsForm.patchValue({
        masterCategory: category.name
      });
      this.selectedCategory = category.categoryID;
      this.categoryNotes = category.notes;
      this.commonService.GetSubCategories(this.appStateService.customerId, this.appStateService.language, category.categoryID,
        res => {
          this.subCategories = res.responseData;
          if ((this.selectedCategory && this.subCategories && this.subCategories.length > 0)) {
            if (this.detailsForm.value.subCategory) {
              this.detailsForm.patchValue({
                subCategory: ''
              });
            }
            this.detailsForm.get('subCategory').setValidators(Validators.required);
            this.detailsForm.get('subCategory').updateValueAndValidity();
          } else {
            this.detailsForm.patchValue({
              subCategory: this.appStateService.select
            });
            this.detailsForm.get('subCategory').clearValidators();
            this.detailsForm.get('subCategory').updateValueAndValidity();
          }
        },
        err => {
          this.toastr.error("Error fetching SubCategories");
        });
    }
  }

  selectSubCategory(category) {
    this.selectedCategory = category.categoryID;
    if (!(category == this.detailsForm.value.subCategory && category == "")) {
      this.detailsForm.patchValue({
        subCategory: category.name
      });
    }
  }

  changeValidity(value) {
    if (this.detailsForm.value.investigationOutcome != value) {
      this.selectedValidity = value;
      this.detailsForm.patchValue({
        investigationOutcome: value
      });
    }
  }

  GenerateNewReport() {
    if (!this.successSubmit) {
      var newReportMessage = "ProxyPopTextTwo" + "<br>" + "ProxyPopTextThree" + "<br> <br> " + "ProxyPopTextFour"
      this.dialogService.confirm(
        "",
        newReportMessage,
        () => {
          window.location.reload();
        },
        () => {
          return;
        },
        "Proceed",
        "Cancel"
      );
    } else {
      window.location.reload();
    }

  }

  incidentDateChange(value) {
    this.detailsForm.patchValue({
      incidentDate: moment(value).format("MM/DD/YYYY")
    });
  }

  getProxyFormDetails() {
    this.isUserDetailsRequired = true;
    var proxyForm = this.detailsForm.value;
    var allSubjects = this.commonService.SubjectsInfo().slice();
    if (!allSubjects || allSubjects.length == 0) {
      this.translationService.get("SubjectRequired").subscribe(text => {
        setTimeout(() => {
          this.scrollToError();
        }, 100);
        this.toastr.error(text);
      });
      return;
    }
    allSubjects.forEach(element => {
      element.type = (element.type == 'Other' ? element.otherType : element.type);
    });
    this.userForm.value.subjectRelationshipToOrganization = this.reportedByForm.value.relationship;
    this.impactedPartyForm.value.subjectRelationshipToOrganization = this.impactedPartyForm.value.jobTitle;
    allSubjects.push(this.userForm.value);
    if (this.impactedPartyForm.value.jobTitle.toUpperCase() == 'TEAM MEMBER') {
      let ipSubject = this.impactedPartyForm.getRawValue();
      ipSubject.jobTitle = ipSubject.title;
      ipSubject.subjectRelationshipToOrganization = this.impactedPartyForm.value.jobTitle;
      allSubjects.push(ipSubject);
    }
    else {
      allSubjects.push(this.impactedPartyForm.value);
    }
    return {
      category: {
        categoryID: this.selectedCategory
      },
      additionalInformation: {
        subjects: allSubjects,
        concern: proxyForm.incidentDescription,
        incidentDate: proxyForm.incidentDate,
        investigation: {
          validity: proxyForm.investigationOutcome,
          notes: proxyForm.investigationSummary,
          name: proxyForm.masterCategory
        },
      },
      basicInformation: {
        userDetails: this.reportedByForm.value,
        location: this.locationForm.value,
        general: {
          relation: this.reportedByForm.value.relationship,
          anonymous: this.reportedByForm.value.relationship.toUpperCase() == 'ANONYMOUS' ? true : false
        }
      },
      customerId: this.appStateService.customerId,
      languageCd: this.appStateService.language,
      hasFiles: this.selectedFiles && this.selectedFiles.length > 0
    }
  }

  browseLocations() {
    this.resetLocationSearch();
    const modalRef = this.modalService.open(BrowseLocationsModalComponent, {
      windowClass: "modal-window",
      backdrop: "static"
    });
    modalRef.componentInstance.proxyForm = true;
    modalRef.componentInstance.selectedLocationOutput.subscribe(location => {
      this.onLocationChange(location);
    });
  }

  private resetLocationSearch() {
    const ele = document.getElementById("locationSearch") as HTMLInputElement;
    ele.value = "";
  }

  onLocationChange(location) {
    this.locationForm.patchValue({
      address: location.address,
      city: location.city,
      state: location.state,
      country: location.country,
      postalCode: location.postalCode,
      name: location.name,
      locationID: +location.locationID,
      nameID: location.nameID,
      level_1: location.level_1,
      level_2: location.level_2,
      level_3: location.level_3,
      level_4: location.level_4,
      level_5: location.level_5,
      level_6: location.level_6,
      level_7: location.level_7,
      level_8: location.level_8
    });
    this.selectedCountry = location.country;
  }

  resetLocations() {
    if (this.cid !== 'CYH') {
      this.resetLocationSearch();
    }
    this.selectedCountry = "";
    this.locationForm.patchValue({
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      name: "",
      locationID: 0,
      nameID: "",
      level_1: "",
      level_2: "",
      level_3: "",
      level_4: "",
      level_5: "",
      level_6: "",
      level_7: "",
      level_8: ""
    });
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(1),
      distinctUntilChanged(),
      switchMap(searchText => this.data(searchText))
    );
  };

  data(searchText) {
    if (searchText.trim().length < 3) {
      if (this.typeaheadInstance) {
        var instance = this.typeaheadInstance._results.find(x =>
          x.isPopupOpen()
        );
        if (instance) {
          instance.dismissPopup();
        }
      }
      return [];
    }

    return this.commonService.getLocationsForTypeahead(this.cid, searchText, true)
      .pipe(map(item => item))
  }

  formatter = x =>
    x.name +
    ", " +
    x.address +
    ", " +
    x.city +
    ", " +
    x.country +
    ", " +
    x.postalCode;

  changeReportedRelation(value) {
    if (value != this.reportedByForm.value.relationship) {
      this.sameasForm.reset({ isImpactedPartDiff: null })
      if (value.toUpperCase() != 'ANONYMOUS') {
        this.reportedByForm.get('firstName').setValidators([Validators.required, Validators.pattern('.*\\S+.*')]);
        this.reportedByForm.get('firstName').updateValueAndValidity();
        this.reportedByForm.get('lastName').setValidators([Validators.required, Validators.pattern('.*\\S+.*')]);
        this.reportedByForm.get('lastName').updateValueAndValidity();
      }
      else {
        this.reportedByForm.get('firstName').clearValidators();
        this.reportedByForm.get('firstName').updateValueAndValidity();
        this.reportedByForm.get('lastName').clearValidators();
        this.reportedByForm.get('lastName').updateValueAndValidity();
      }
    }
    if (!(value == this.reportedByForm.value.relationship && value == "")) {
      this.reportedByForm.reset({ relationship: value });
    }
  }

  changeImpactrRelation(value) {
    if (value != this.impactedPartyForm.value.jobTitle) {
      this.impactedPartyForm.patchValue({
        id: 0,
        jobTitle: value,
        type: "Impacted Party",
        employeeUId: '',
        firstName: '',
        lastName: '',
        title: '',
      });
      if (value.toUpperCase() != 'ANONYMOUS') {
        this.impactedPartyForm.get('firstName').setValidators([Validators.required, Validators.pattern('.*\\S+.*')]);
        this.impactedPartyForm.get('firstName').updateValueAndValidity();
        this.impactedPartyForm.get('lastName').setValidators([Validators.required, Validators.pattern('.*\\S+.*')]);
        this.impactedPartyForm.get('lastName').updateValueAndValidity();
        if (value.toUpperCase() == "TEAM MEMBER") {
          this.impactedPartyForm.get("employeeUId").setValidators([Validators.minLength(8), Validators.maxLength(8)]);
          this.impactedPartyForm.get('employeeUId').updateValueAndValidity();
        }
        else {
          this.impactedPartyForm.get("employeeUId").clearValidators();
          this.impactedPartyForm.get('employeeUId').updateValueAndValidity();
        }
      }
      else {
        this.impactedPartyForm.get('firstName').clearValidators();
        this.impactedPartyForm.get('firstName').updateValueAndValidity();
        this.impactedPartyForm.get('lastName').clearValidators();
        this.impactedPartyForm.get('lastName').updateValueAndValidity();
        this.impactedPartyForm.get("employeeUId").clearValidators();
        this.impactedPartyForm.get('employeeUId').updateValueAndValidity();
      }
    }
    if (!(value == this.impactedPartyForm.value.jobTitle && value == "")) {
      this.impactedPartyForm.reset({ jobTitle: value, id: 0, type: "Impacted Party" });
    }
  }

  getLocations(): any {
    this.isLoading = true;
    this.commonService.getLocations(
      this.appStateService.customerId,
      data => {
        if (data) {
          this.locations = data;
          this.countries = this.locations.map(x => x.country)
            ? this.locations.map(x => x.country).filter(this.unique)
            : null;
        }
        this.isLoading = false;
      },
      err => {
        this.showError(err);
        this.isLoading = false;
      }
    );
  }

  showError(err: any) {
    this.toastr.error(err.message);
  }

  changeCountry(value) {
    this.selectedCountry = value;
    this.locationForm.patchValue({
      country: value
    });
  }

  onBackClick() {
    this.router.navigate(["/MCR"]);
  }

  onNextClick() {
    this.detailsForm.patchValue({
      incidentDescription: this.detailsForm.controls.incidentDescription.value.trim(),
      investigationSummary: this.detailsForm.controls.investigationSummary.value.trim()
    })
    var proxyData = this.getProxyFormDetails();
    if (proxyData && (this.uidValue != this.userForm.value.employeeUId || (this.reportedByForm.value.employeeUId && this.ruidValue != this.reportedByForm.value.employeeUId) || (this.impactedPartyForm.value.employeeUId && this.iuidValue != this.impactedPartyForm.value.employeeUId))) {
      setTimeout(() => {
        this.scrollToError();
      }, 100);
      this.toastr.error("Please verify 8-digit Team Member Number");
      return false;
    }
    if (proxyData) {
      if (this.userForm.invalid || this.locationForm.invalid || this.reportedByForm.invalid || this.sameasForm.invalid || this.detailsForm.invalid || this.impactedPartyForm.invalid) {
        setTimeout(() => {
          this.scrollToError();
        }, 100);
        return false;
      }
      this.isLoading = true;
      proxyData.additionalInformation.concern = this.detailsForm.controls.incidentDescription.value.replace(/\n/g, '<br/>');
      proxyData.additionalInformation.investigation.notes = this.detailsForm.controls.investigationSummary.value.replace(/\n/g, '<br/>');
      this.commonService.saveReport(proxyData, res => {
        this.isUserDetailsRequired = true;
        this.appStateService.reportId = res.CRSID;
        this.uploadFiles();
      },
        err => {
          this.successSubmit = false;
          this.showError(err);
          this.isLoading = false;
        });
    }
  }

  get userControl() {
    return this.userForm.controls;
  }

  get locationControl() {
    return this.locationForm.controls;
  }

  get reportedByFormControl() {
    return this.reportedByForm.controls;
  }

  get impactedPartyFormControl() {
    return this.impactedPartyForm.controls;
  }

  get sameasFormControl() {
    return this.sameasForm.controls;
  }

  get detailsFormControl() {
    return this.detailsForm.controls;
  }

  numericOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode >= 48 && charCode <= 57) ||
      charCode === 32 ||
      charCode === 43
    ) {
      return true;
    }
    return false;
  }

  onFileChange(target) {
    var files = target.files;
    let validFileExts = AppConstants.ValidFileExtensions;
    this.disableUpload = false;
    if (files && files.length > 0) {
      let uniqueFileList = this.selectedFiles.length > 0 ?
        [...files].filter(file => !this.selectedFiles.find(sf => file.name === sf.file.name)) : files;
      for (let file of uniqueFileList) {
        let splitContent = file.name.split('.');
        let fileExt = splitContent[splitContent.length - 1];
        let type = file.type;
        if (validFileExts.indexOf(fileExt.toUpperCase()) === -1 || validFileExts.indexOf(type.toUpperCase()) === -1) {
          this.translationService.get('ReqExtension').subscribe(text => {
            this.toastr.error(text);
          });
          break;
        }
        else if (file.size > this.maxfileSize) {
          this.translationService.get('ReqMaxSize').subscribe(text => {
            this.toastr.error(text);
          });
          break;
        }
        else {
          this.uploadedFilesSize += file.size;
          if (this.uploadedFilesSize > this.maxSize) {
            this.translationService.get('ProxyFileLimitExceed').subscribe(text => {
              this.uploadedFilesSize -= file.size;
              this.toastr.error(text);
            });
            break;
          }
          if (this.uploadedFilesSize >= this.maxSize) {
            this.disableUpload = true;
          }
          this.selectedFiles.push({ file: file, description: (file.description ? file.description : '') })
        }
      }
    }
    target.value = null;
  }

  removeFile(selectedFile) {
    // this.selectedFiles = this.selectedFiles.filter(f => f.file.name !== selectedFile.file.name);
    var filteredFiles = [];
    this.disableUpload = false;
    this.selectedFiles.forEach(f => {
      if (f.file.name !== selectedFile.file.name) {
        filteredFiles.push(f);
      }
      else {
        this.uploadedFilesSize -= f.file.size;
      }
    });
    if (this.uploadedFilesSize >= this.maxSize) {
      this.disableUpload = true;
    }
    this.selectedFiles = filteredFiles;
  }

  isSameAsReporterChange(e) {
    if (e.target.value == "No") {
      var reporterInfo = this.reportedByForm.value;
      this.iuidValue = reporterInfo.employeeUId;
      this.impactedPartyForm.patchValue({
        id: 0,
        firstName: reporterInfo.firstName,
        lastName: reporterInfo.lastName,
        jobTitle: reporterInfo.relationship,
        employeeUId: reporterInfo.employeeUId,
        title: "",
        type: "Impacted Party",
      });
      if (reporterInfo.relationship.toUpperCase() != 'ANONYMOUS') {
        this.impactedPartyForm.get('firstName').setValidators([Validators.required, Validators.pattern('.*\\S+.*')]);
        this.impactedPartyForm.get('firstName').updateValueAndValidity();
        this.impactedPartyForm.get('lastName').setValidators([Validators.required, Validators.pattern('.*\\S+.*')]);
        this.impactedPartyForm.get('lastName').updateValueAndValidity();
        this.impactedPartyForm.get('employeeUId').clearValidators();
        this.impactedPartyForm.get('employeeUId').updateValueAndValidity();
      }
      else {
        this.impactedPartyForm.get('firstName').clearValidators();
        this.impactedPartyForm.get('firstName').updateValueAndValidity();
        this.impactedPartyForm.get('lastName').clearValidators();
        this.impactedPartyForm.get('lastName').updateValueAndValidity();
        this.impactedPartyForm.get('employeeUId').clearValidators();
        this.impactedPartyForm.get('employeeUId').updateValueAndValidity();
      }
    }
    else {
      this.impactedPartyForm.reset({ id: 0, jobTitle: '' });
    }
  }

  getUser(uid, ulabel) {
    if (uid && uid.value) {
      this.getEmployee(uid, ulabel);
      if (ulabel == AppConstants.userInfo) {
        this.uidValue = uid.value;
      }
      else if (ulabel == AppConstants.reportBy && uid.value) {
        this.ruidValue = uid.value;
      }
      else if (ulabel == AppConstants.impactedParty && uid.value) {
        this.iuidValue = uid.value;
      }
    }
  }

  getEmployee(uid, ulabel) {
    this.isLoading = true;
    this.commonService.getEmployee(this.cid, uid.value, res => {
      if (ulabel == AppConstants.userInfo) {
        this.setUserInfo(res);
      } else if (ulabel == AppConstants.reportBy) {
        this.setReportBy(res);
      }
      else if (ulabel == AppConstants.impactedParty) {
        this.setImpactedParty(res);
      }
      this.isLoading = false;
    },
      err => {
        this.getUserError(err);
        this.isLoading = false;
      });
  }

  getUserError(err) {
    this.toastr.error(err);
  }

  setUserInfo(userDetails) {
    if (userDetails) {
      this.userForm.patchValue({
        id: 0,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        jobTitle: userDetails.title ? userDetails.title : (userDetails.jobTitle ? userDetails.jobTitle : ''),
        address: userDetails.address,
        city: userDetails.city,
        state: userDetails.state,
        postalCode: userDetails.postalCode,
        phone: userDetails.phone ? userDetails.phone : (userDetails.phone_Home ? userDetails.phone_Home : ''),
        email: userDetails.email,
        employeeUId: userDetails.uid
      });
    }
    else {
      this.userForm.reset({ id: 0 });
    }
    if (this.uid) {
      this.uid.nativeElement.value = this.userForm.value.employeeUId;
    }
  }

  setReportBy(userDetails) {
    if (userDetails) {
      this.reportedByForm.patchValue({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phone: userDetails.phone ? userDetails.phone : (userDetails.phone_Home ? userDetails.phone_Home : ''),
        email: userDetails.email,
        employeeUId: userDetails.uid
      });
    }
    else {
      this.changeReportedRelation(this.reportedByForm.value.relationship);
    }
    if (this.ruid) {
      this.ruid.nativeElement.value = this.reportedByForm.value.employeeUId;
    }
  }

  setImpactedParty(userDetails) {
    if (userDetails) {
      this.impactedPartyForm.patchValue({
        id: 0,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phone: userDetails.phone ? userDetails.phone : (userDetails.phone_Home ? userDetails.phone_Home : ''),
        email: userDetails.email,
        employeeUId: userDetails.uid,
        title: userDetails.title,
        type: "Impacted Party"
      });
    }
    else {
      this.changeImpactrRelation(this.impactedPartyForm.value.jobTitle);
    }
    if (this.iuid) {
      this.iuid.nativeElement.value = this.impactedPartyForm.value.employeeUId;
    }
  }

  getAttUserSuccess(res) {
    this.setUserInfo(res);
  }

  uploadFiles(): any {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.commonService.uploadDocuments(
        this.appStateService.reportId + "-01",
        this.selectedFiles,
        res => {
          if (res) {
            this.successSubmit = true;
            this.commonService.createAudit(
              this.appStateService.customerId,
              "New Report - Success " + this.appStateService.reportId,
              "/success"
            );
            this.isLoading = false;
          }
        },
        err => {
          this.showError(err);
          this.isLoading = false;
        }
      );
    } else {
      this.successSubmit = true;
      this.commonService.createAudit(
        this.appStateService.customerId,
        "New Report - Success " + this.appStateService.reportId,
        "/success"
      );
      this.isLoading = false;
    }
  }

  scrollToError(): void {
    var elementsWithError = Array.from(document.getElementsByClassName('is-invalid'));
    var allSubjects = this.commonService.SubjectsInfo().slice();
    if (!allSubjects || allSubjects.length == 0) {
      document.getElementsByClassName('proxy-subject')[0].scrollIntoView({ block: 'center' });
    } else if (this.uidValue != this.userForm.value.employeeUId) {
      document.getElementById('informationLabels').scrollIntoView({ block: 'center' });
      document.getElementById('uid').classList.add('ng-invalid')
    } else if (this.reportedByForm.value.employeeUId && this.ruidValue != this.reportedByForm.value.employeeUId) {
      document.getElementById('reportedByLabels').scrollIntoView({ block: 'center' });
      document.getElementById('ruid').classList.add('ng-invalid')
    }
    else if (this.impactedPartyForm.value.employeeUId && this.iuidValue != this.impactedPartyForm.value.employeeUId) {
      document.getElementById('impactedPartyLabels').scrollIntoView({ block: 'center' });
      document.getElementById('iuid').classList.add('ng-invalid')
    } else if (elementsWithError && elementsWithError.length > 0) {
      var firstElementWithError = elementsWithError[0];
      if (firstElementWithError) {
        var isElementVisible = firstElementWithError.closest('.underline.show');

        if (!isElementVisible) {
          var collapseArrow = firstElementWithError.closest('.proxy-custom-control').getElementsByClassName('arrow-collapse')[0] as HTMLElement;
          collapseArrow.click();
          firstElementWithError.scrollIntoView({ block: 'center' });
        } else {
          firstElementWithError.scrollIntoView({ block: 'center' });
        }
      }
      this.expandUnansweredQuestions(elementsWithError);
    }

  }

  expandUnansweredQuestions(elements) {
    elements.forEach(e => {
      var isQuestionVisible = e.closest('.underline.show');
      if (!isQuestionVisible) {
        var collapseArrow = e.closest('.proxy-custom-control').getElementsByClassName('arrow-collapse')[0] as HTMLElement;
        collapseArrow.click();
      }
    });
  }
}


