export class AppConstants {
    public static get impactedParty(): string { return "impactedParty"; }
    public static get reportBy(): string { return "reportBy"; }
    public static get userInfo(): string { return "userInfo"; }
    public static get ValidFileExtensions(): any {
        return [
            "JPG",
            "JPEG",
            "PNG",
            "GIF",
            "DOC",
            "DOCX",
            "XLS",
            "XLSX",
            "PDF",
            "RTF",
            "BMP",
            "TIFF",
            "TXT",
            "CSV",
            "HTM",
            "HTML",
            "PPT",
            "PPTX",
            "XML",
            "MP4",
            "MOV",
            "MPEG",
            "MPE",
            "MPG",
            "AVI",
            "OGG",
            "MKV",
            "EML"
        ]
    };
    public static get Severity(): any { return 3 }
    public static get MCRLiteCustomer(): string { return "MCR Lite" }
    public static get MCRReportMaxLimit(): any { return 50 }
    public static get EmailPattern(): any { return "^[a-zA-Z0-9._%+-]+['a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"}
    public static get FileMaxSize(): number {return 52428800 };
}
