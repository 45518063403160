import { Component, OnInit } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AppStateService } from "./appStateService";
import { CommonService } from "./common.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Guid } from "guid-typescript";
import { AppConstants } from "./shared/app-constants";
import { filter, take, takeWhile } from 'rxjs/operators';

export let browserRefresh = false;
declare const gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})

export class AppComponent {
  title = "MyComplainceReport.com";
  subscription: Subscription;
  subscriptionOfCRSID: Subscription;
  SurveyID: string;
  RespondentsID: string;
  constructor(
    private router: Router,
    private commonService: CommonService,
    public appStateService: AppStateService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscription = router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        var cid = router.getCurrentNavigation().extractedUrl.queryParams.cid;
        this.appStateService.customerId = cid;
        if (browserRefresh && (event.url.includes("/report") || event.url.includes("/form"))) {
          if (event.url.includes("/form") && cid != 'TGTHR') {
            this.appStateService.customerId = "";
            this.router.navigate(["/MCR"]);
          }
          this.commonService.GetIssuesCount(cid,
            (res) => {
              if (res >= AppConstants.MCRReportMaxLimit) {
                this.appStateService.customerId = "";
                this.toastr.error(
                  "Web Form submissions are currently unavailable for this account. Please contact the program administrator at your company to request a change or report your issue through another channel."
                );
                this.router.navigate(["/MCR"]);
              }
              else {
                this.getCustomerDetails(cid);
              }
            },
            err => { });
        }
      }
    });

    this.gaScript();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      })
    })
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    // Listen to message from child window
    let that = this;
    let count = 1;

    eventer(messageEvent, function (e) {
      //window.addEventListener('message', function (e) {
      //console.log('parent received message!:  ', e.data);
      if (e.origin == "https://app.keysurvey.com" && e.data) {
        if (e.data.includes("SurveyID")) {
          var data = JSON.parse(e.data);
          that.SurveyID = data.SurveyID;
          that.RespondentsID = data.RespondentsID;
        }
        if (e.data.includes("FormSubmitted")) {
          const source = interval(10000);
          that.subscriptionOfCRSID = source.pipe(takeWhile(val => {
            //console.log(val);
            if (val <= 4) {
              return true;
            } else {
              that.subscriptionOfCRSID && that.subscriptionOfCRSID.unsubscribe();
              return false;
            }
          })).subscribe(val => {
            that.getCRSID(that.SurveyID, that.RespondentsID, that.appStateService.customerId);
          });
        }
      }
    }, false);

  }
  private getCRSID(SurveyID: string, RespondentsID: string, customerId: string) {
    this.commonService.getCRSID(
      SurveyID,
      RespondentsID,
      customerId,
      res => {
        if (res.CRSID != null) {
          this.subscriptionOfCRSID && this.subscriptionOfCRSID.unsubscribe();
          this.appStateService.reportId = res.CRSID;
          this.appStateService.callbackDate = res.CallbackDate;
          this.appStateService.isSubmitted = true;
          this.router.navigate(["/success"], { relativeTo: this.activatedRoute });
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }
  private getCustomerDetails(cid: any) {
    this.commonService.GetCustomerById(
      cid,
      res => {
        sessionStorage.setItem("customerId", cid);
        sessionStorage.setItem("customerName", res.name);
        this.appStateService.customerName = res.name;
        this.appStateService.mcrCallerType = res.pCallerType
          ? res.pCallerType.split(/[\r\n,\r,\n]/).filter(v => v !== "" && v !== "\r")
          : null;
        this.appStateService.mcrValidity = res.pValidity
          ? res.pValidity.split(/[\r\n,\r,\n]/).filter(v => v !== "" && v !== "\r")
          : null;
        this.appStateService.subjectTypes = res.pSubjectType
          ? res.pSubjectType.split(/[\r\n,\r,\n]/).filter(v => v !== "" && v !== "\r")
          : null;
        this.appStateService.IsCurrentCustomerMCRLite = (res.mcrTier == AppConstants.MCRLiteCustomer) ? true : false;
      },
      err => {
        this.appStateService.customerId = "";
        this.toastr.error(
          "The Access code you entered does not belong to a MyComplianceReport customer or this customer has deactivated the Type of Report you have chosen."
        );
        this.router.navigate(["/MCR"]);
      }
    );
  }

  gaScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.appStateService.enviromentData.gaTrackingId;
    document.head.prepend(gtagScript);
    gtag('config', this.appStateService.enviromentData.gaTrackingId, { send_page_view: false });
  }

  ngOnInit() {
    sessionStorage.clear();
    sessionStorage.setItem("session", Guid.create().toString());
  }
}
