import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppStateService } from "src/app/appStateService";
@Component({
  selector: "app-eu-disclaimer",
  templateUrl: "./eu-disclaimer.component.html",
  styleUrls: ["./eu-disclaimer.component.scss"]
})
export class EUDisclaimerComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, public appStateService: AppStateService) { }
  ngOnInit() { }
}
