import { Component, Input, NgModuleRef, OnInit, ViewEncapsulation } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddSubjectsComponent } from "../add-subjects/add-subjects.component";
import { CommonService } from "../common.service";
import { AppStateService } from "../appStateService";

@Component({
  selector: "app-subjects",
  templateUrl: "./subjects.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./subjects.component.scss"]
})
export class SubjectsComponent implements OnInit {
  subjects: any = [];
  @Input() proxyForm = false;
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private appStateService: AppStateService
  ) { }

  ngOnInit() {
    this.commonService.SubjectsInfo = () => {
      return this.subjects;
    };

    if (
      this.appStateService.additionalDetails &&
      this.appStateService.additionalDetails.subjects
    ) {
      this.subjects = this.appStateService.additionalDetails.subjects;
    }

    this.commonService.getSubjectTypes(
      this.appStateService.customerId,
      res => {
        this.appStateService.subjectTypes = res;
      },
      err => { }
    );
    this.commonService.getSubjectRelationship(
      this.appStateService.customerId,
      res => {
        this.appStateService.subjectRelationship = res.relationship;
        this.appStateService.showSubjectRelationship= res.showSubjectRelationship
      },
      err => { }
    );
  }
  
  openSubject(subject = null) {
    if (this.proxyForm && !subject && this.subjects.length >= 9) {
      return false;
    }
    const modalRef = this.modalService.open(AddSubjectsComponent, {
      size: "lg",
      centered: true,
      backdrop: "static"
    });
    if(this.proxyForm && subject != null){
      subject.type = subject.subjectRelationshipToOrganization;
    }
    modalRef.componentInstance.subject = subject;
    modalRef.componentInstance.proxyForm = this.proxyForm;
    modalRef.componentInstance.OnSave.subscribe(subjectDetails => {
      this.saveSubject(subjectDetails);
    });
    }

  saveSubject(subject) {
    if (subject.id) {
      this.subjects[subject.id - 1] = subject;
    } else {
      subject.id = this.subjects ? this.subjects.length + 1 : 1;
      this.subjects.push(subject);
    }
    this.subjects = this.subjects.slice();
  }

  deleteSubject(index) {
    this.subjects.splice(index, 1);
  }
}
