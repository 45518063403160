import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "gridFilter"
})
export class GridFilterPipe implements PipeTransform {
  filteredItems: any[];
  transform(
    items: any[],
    search: { [key: string]: any },
    filter: { [key: string]: any }
  ): any[] {
    this.filteredItems = items;
    if (!items) {
      return [];
    }
    if (!search && !filter) {
      return items;
    }

    for (var propName in filter) {
      if (!filter[propName]) {
        delete filter[propName];
      }
    }
    if (filter && JSON.stringify(filter) !== "{}") {
      this.filteredItems = this.filteredItems.filter(item => {
        let notMatchingField = Object.keys(filter).find(
          key =>
            !item[key] ||
            (item[key] &&
              item[key].length > 0 &&
              !item[key]
                .toLocaleLowerCase()
                .includes(filter[key].toLocaleLowerCase()))
        );

        return !notMatchingField; // true if matches any field
      });
    }

    if (search) {
      this.filteredItems = this.filteredItems.filter(item => {
        let MatchingField = Object.keys(search).find(
          key =>
            item[key] &&
            item[key].length > 0 &&
            item[key]
              .toLocaleLowerCase()
              .includes(search[key].toLocaleLowerCase())
        );

        return MatchingField; // true if matches any field
      });
    }
    return this.filteredItems;
  }
}
