import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BeginReportComponent } from "./begin-report/begin-report.component";
import { CreateReportComponent } from "./create-report/create-report.component";
import { FollowUpReportComponent } from "./follow-up-report/follow-up-report.component";
import { McrReportComponent } from "./mcr-report/mcr-report.component";
import { ReportSummaryComponent } from "./report-summary/report-summary.component";
import { EUDisclaimerComponent } from "./static/eu-disclaimer/eu-disclaimer.component";
import { FaqComponent } from "./static/faq/faq.component";
import { TermsAndPoliciesComponent } from "./static/terms-and-policies/terms-and-policies.component";
import { SuccessCopyComponent } from "./success-copy/success-copy.component";
import { AuthGuard } from "./auth.guard";
import { RenrDisclaimerComponent } from "./renr-disclaimer/renr-disclaimer.component";
import { FormComponent } from "./form/form/form.component";
import { BeginReportLandingComponent } from "./landing-page/begin-report-landing/begin-report-landing.component";
import { FollowUpLandingComponent } from "./landing-page/follow-up-landing/follow-up-landing.component";

const routes: Routes = [
  { path: "MCR", component: BeginReportLandingComponent },
  { path: "report", component: BeginReportComponent },
  { path: "form", component: FormComponent },
  { path: "faq", component: FaqComponent },
  { path: "terms", component: TermsAndPoliciesComponent },
  { path: "report-summary", component: ReportSummaryComponent },
  { path: "followUp", component: FollowUpLandingComponent },
  {
    path: "followUp-report",
    component: FollowUpReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "success",
    component: SuccessCopyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "renr-disclaimer",
    component: RenrDisclaimerComponent
  },
  { path: "**", redirectTo: "MCR", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
