import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  HostListener
} from "@angular/core";
import { CommonService } from "../common.service";
import { AppStateService } from "../appStateService";
import { ActivatedRoute } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { ToastrService } from "ngx-toastr";
import Engine from "json-rules-engine-simplified";
import { AppConstants } from "../shared/app-constants";
import { interval, Subscription, timer } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "app-create-report",
  templateUrl: "./create-report.component.html",
  styleUrls: ["./create-report.component.scss"]
})
export class CreateReportComponent implements OnInit {
  cid: any;
  languageSubscription: any;
  groupedCategories: any;
  rules = [];
  ruleEngine: any;
  formRuleData: Array<any> = [];
  selectedCategory: any;
  childs: Array<number> = [];
  rulesOnQuestionValueChange = [];
  count: number;
  isSummary = false;
  questions: any;
  @Output() ReportNav = new EventEmitter();
  isRequired: boolean;
  isShowFooter = false;
  receivedLocation: any;
  receivedAnonymousValue: any;
  categoriesErrorMessage: any;
  isKeySurveyForm: boolean;
  subscription !: Subscription;
  recordResponse = true;
  keySurveyFormURL: any;
  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private appStateService: AppStateService,
    private deviceService: DeviceDetectorService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
  ) {
  }
  ngOnInit() {
    this.commonService.SelectedLocationChange.subscribe(location => {
      this.receivedLocation = location;
    });
    
    this.appStateService.showAnonymousValue$.subscribe((value: boolean) => {
      this.receivedAnonymousValue=value;
    }); 
    window.scroll(0, 0);
    if (this.deviceService.isDesktop()) {
      this.isShowFooter = false;
    } else {
      this.isShowFooter = true;
      this.commonService.NotifyTabChange.next();
    }
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
    });
    this.appStateService.createReportLanguageSubscription = this.appStateService.languageSub.subscribe(
      sub => {
        this.isSummary = false;
        setTimeout(() => {
          this.onTabNavigation(0);
          this.GetCategoryData(sub);
          this.categoryChange(null);
          this.questions = null;
          this.commonService.categoryInfo(false);
        }, 50);
      }
    );

    if (this.appStateService.additionalDetails) {
      if (this.appStateService.additionalDetails.questions) {
        this.questions = this.appStateService.additionalDetails.questions;
      }
      if (this.appStateService.additionalDetails.rules) {
        this.rules = this.appStateService.additionalDetails.rules;
      }
    }

    if (this.appStateService.selectedCategory) {
      this.selectedCategory = this.appStateService.selectedCategory;
    }
    this.GetCategoryData(this.appStateService.language);
    if (
      this.appStateService.selectedCategory ||
      this.appStateService.generalDetails ||
      this.appStateService.additionalDetails
    ) {
      setTimeout(() => {
        this.checkIfRequiredFieldsFilled(0);
        this.checkIfRequiredFieldsFilled(1);
        this.checkIfRequiredFieldsFilled(2);
      }, 50);
    }
    this.GetKeySurveyConfigData();
  }

  getQuestionsRules() {
    this.commonService.GetQuestionRules(
      this.cid,
      res => {
        this.setQuestionRules(res);
      },
      () => { }
    );
  }

  setQuestionRules(quesRules) {
    this.rules = [];
    quesRules
      .map(x => x.rules)
      .forEach(x => {
        this.rules.push(JSON.parse(x));
      });
    if (this.rules && this.questions) {
      this.setRuleExistsOnQuestions();
      this.setShowQuestionOnQuestions(this.rules);
      this.setFormRuleData(this.rules);
      this.executeRuleOnFormRuleData();
    }
  }

  private setRuleExistsOnQuestions() {
    if (this.questions) {
      this.rules.forEach(x => {
        // set RuleExists value based on rules;
        this.questions.forEach(q => {
          if (q.rules != null || (x.conditions.QuestionId && +q.questionID === +x.conditions.QuestionId.is)
            || (x.event.params.questionIds && x.conditions.QuestionId === undefined && q.questionID === +x.event.params.questionIds)) {
            q.RuleExists = true;
          }
        });
      });
    }
  }

  private setShowQuestionOnQuestions(rules) {
    this.questions.forEach(x => {
      x.showQuestion = true;
      if (x.rules) {
        this.rules.push(JSON.parse(x.rules));
      }
    });
    rules.forEach(x => {
      // set ShowQuestion value based on rules.
      if (this.questions.find(
        q => (x.conditions.QuestionId && +q.questionID === +x.conditions.QuestionId.is)
          || (x.event.params.questionIds && x.conditions.QuestionId === undefined && q.questionID === +x.event.params.questionIds))
      ) {
        this.questions
          .filter(item =>
            x.event.params.questionIds
              .split(",")
              .includes(item.questionID.toString())
          )
          .forEach(element => {
            element.showQuestion = false;
          });
      }
    });
  
}

  setFormRuleData(rules) {
    this.formRuleData = new Array<any>();
    if (this.appStateService.generalDetails) {
      rules.forEach(x => {
        const ruleData = {};
        this.questions
          .filter(q => q.RuleExists)
          .forEach(y => {
            if ((x.conditions.QuestionId && +y.questionID === +x.conditions.QuestionId.is)
              || (x.event.params.questionIds && x.conditions.QuestionId === undefined && y.questionID === +x.event.params.questionIds)) {
              ruleData["QuestionId"] = +y.questionID;
              ruleData["QuestionValue"] = y.answer ? y.answer.trim() : y.answer;
              ruleData["Severity"] = AppConstants.Severity;
              ruleData["Location Name"] = this.appStateService.generalDetails.location.name
                ? this.appStateService.generalDetails.location.name
                : "";
              ruleData["Location State"] = this.appStateService.generalDetails.location.state
                ? this.appStateService.generalDetails.location.state
                : "";
              ruleData["Location Country"] = this.appStateService.generalDetails.location.country
                ? this.appStateService.generalDetails.location.country
                : "";
              ruleData["Location Level1"] = this.appStateService.generalDetails.location.level_1
                ? this.appStateService.generalDetails.location.level_1
                : "";
              ruleData["Location Level2"] = this.appStateService.generalDetails.location.level_2
                ? this.appStateService.generalDetails.location.level_2
                : "";
              ruleData["Location Level3"] = this.appStateService.generalDetails.location.level_3
                ? this.appStateService.generalDetails.location.level_3
                : "";
              ruleData["Location Level4"] = this.appStateService.generalDetails.location.level_4
                ? this.appStateService.generalDetails.location.level_4
                : "";
              ruleData["Location Level5"] = this.appStateService.generalDetails.location.level_5
                ? this.appStateService.generalDetails.location.level_5
                : "";
              ruleData["Location Level6"] = this.appStateService.generalDetails.location.level_6
                ? this.appStateService.generalDetails.location.level_6
                : "";
              ruleData["Location Level7"] = this.appStateService.generalDetails.location.level_7
                ? this.appStateService.generalDetails.location.level_7
                : "";
              ruleData["Location Level8"] = this.appStateService.generalDetails.location.level_8
                ? this.appStateService.generalDetails.location.level_8
                : "";
              ruleData["Location NameID"] = this.appStateService.generalDetails.location.nameID
                ? this.appStateService.generalDetails.location.nameID
                : "";
              ruleData["Anonymous"] = this.appStateService.generalDetails.general.anonymous;
              this.formRuleData.push(ruleData);
            }
          });
      });
    }
    this.ruleEngine = new Engine(rules);
  }


  private executeRuleOnFormRuleData() {
    this.formRuleData.forEach(x => {
      this.executeQuestionsRules(x);
    });
    this.questions = this.questions.slice();
  }

  executeQuestionsRules(rule) {
    // Run the engine to evaluate
    this.ruleEngine.run(rule).then(events => {
      // run() returns remove event
      events.map(event => {
        this.questions
          .filter(item => {
            if (event.params && event.params.questionIds
              .split(",")
              .includes(item.questionID.toString())) {
              item.showQuestion = true;
              return item;
            }
          });
      });
    });
  }

  GetQuestions() {
    this.commonService.getQuestions(
      this.cid,
      this.appStateService.language,
      this.selectedCategory.categoryID,
      res => {
        this.questions = res;
        this.getQuestionsRules();
      },
      err => {
        this.showError(err);
      }
    );
  }
  async ruleQuestionValueChange(quest) {
    this.count = 0;
    this.childs = [];
    this.rulesOnQuestionValueChange = [];
    this.childs.push(+quest.questionID);
    await this.getRulesQuestionsForEachRule();
    await this.setFormRuleData(this.rulesOnQuestionValueChange);
    this.executeRuleOnFormRuleData();
  }

  getRulesQuestionsForEachRule() {
    let rules = this.rules.filter(
      x => x.conditions.QuestionId && +x.conditions.QuestionId.is === +this.childs[0]
    );
    if (rules.length > 0) {
      rules.forEach(rule => {
        this.rulesOnQuestionValueChange.push(rule);
        this.getRuledQuestions(+this.childs[0], rule);
      });
    }
  }

  getRuledQuestions(questId: number, rule = null) {
    this.count++;
    let quest = [];
    if (!rule) {
      let rules = this.rules.filter(
        x => ((x.conditions.QuestionId && x.conditions.QuestionId.is === questId)
          || (x.event.params.questionIds && x.conditions.QuestionId === undefined && +x.event.params.questionIds === questId))
      );
      if (rules.length > 0) {
        rule = rules[0];
        this.rulesOnQuestionValueChange.push(rule);
      }
    }
    if (rule && rule.event.params) {
      quest = rule.event.params.questionIds.split(",");
      this.questions
        .filter(item =>
          rule.event.params.questionIds
            .split(",")
            .includes(item.questionID.toString())
        )
        .forEach(element => {
          element.answer = null;
          element.showQuestion = false;
        });
    }
    if (quest.length > 0) {
      quest.forEach(element => {
        if (!this.childs.includes(+element)) {
          this.childs.push(+element);
        }
      });
    }
    if (this.childs.length > this.count) {
      this.getRuledQuestions(this.childs[this.count]);
    }
  }

  showError(err: any) {
    this.toastr.error(err.message);
  }

  GetCategoryData(lang) {
    this.commonService.GetCategories(
      this.appStateService.customerId,
      lang,
      this.appStateService.countryId,
      data => {
        this.groupedCategories = this.appStateService.IsCurrentCustomerMCRLite ? data.responseData : this.commonService.groupBy(data.responseData, "categoryGroupName");
        this.categoriesErrorMessage = data.errorMessage;
        this.appStateService.categories = this.groupedCategories;
        setTimeout(() => {
          this.checkIfScrollbarExist();
        }, 50);
      },
      err => {
        if (err.status === 404) {
          this.groupedCategories = [];
        } else {
          this.showError(err);
        }
      }
    );
  }

  categoryChange(value) {
    this.selectedCategory = value;
    this.appStateService.selectedCategory = value;
    if (!this.selectedCategory) {
      this.questions = null;
      this.checkIfRequiredFieldsFilled(0);
      this.checkIfRequiredFieldsFilled(1);
      this.checkIfRequiredFieldsFilled(2);
    }
    this.GetQuestions()
  }

  onBackClick() {
    let res;
    if (this.deviceService.isMobile()) {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("mobileNavs")
      );
    } else {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("webNavs")
      );
    }
    this.onTabNavigation(
      res.findIndex(x => x.className.includes("active")) - 1
    );
  }

  onBackDetails() {
    this.navigateToSpecificTab("report");
    window.scroll(0, 0);
  }


  


  private setShowQuestionsBasedOnAnonymousAndLocation(receivedAnonymousValue: boolean) {
    this.questions.forEach((question: any) => {
      if (question.rules) {
        const rules = JSON.parse(question.rules);
        
        let isAnonymousMatch = true;
        let isLocationMatch = true;
        if (rules.conditions && rules.conditions.Anonymous) {
          if (rules.conditions.Anonymous.is !== receivedAnonymousValue) {
            isAnonymousMatch = false;
          }
        }
        if (this.receivedLocation) {
          const receivedCountryName = this.receivedLocation.name;
          const receivedCountry = this.receivedLocation.country;
          const receivedState = this.receivedLocation.state;
          const receivedLevel1 = this.receivedLocation.level_1;
          const receivedLevel2 = this.receivedLocation.level_2;
          const receivedLevel3 = this.receivedLocation.level_3;
          const receivedLevel4 = this.receivedLocation.level_4;
          const receivedLevel5 = this.receivedLocation.level_5;
          const receivedLevel6 = this.receivedLocation.level_6;
          const receivedLevel7 = this.receivedLocation.level_7;
          const receivedLevel8 = this.receivedLocation.level_8;
  
          const countryName = rules.conditions['Location Name'];
          const country = rules.conditions['Location Country'];
          const state = rules.conditions['Location State'];
          const level1 = rules.conditions['Location Level1'];
          const level2 = rules.conditions['Location Level2'];
          const level3 = rules.conditions['Location Level3'];
          const level4 = rules.conditions['Location Level4'];
          const level5 = rules.conditions['Location Level5'];
          const level6 = rules.conditions['Location Level6'];
          const level7 = rules.conditions['Location Level7'];
          const level8 = rules.conditions['Location Level8'];
  
          if ((countryName && countryName.is !== receivedCountryName)
            || (country && country.is !== receivedCountry)
            || (state && state.is !== receivedState)
            || (level1 && level1.is !== receivedLevel1)
            || (level2 && level2.is !== receivedLevel2)
            || (level3 && level3.is !== receivedLevel3)
            || (level4 && level4.is !== receivedLevel4)
            || (level5 && level5.is !== receivedLevel5)
            || (level6 && level6.is !== receivedLevel6)
            || (level7 && level7.is !== receivedLevel7)
            || (level8 && level8.is !== receivedLevel8)) {
            isLocationMatch = false;
          }
        }
        if (isAnonymousMatch && isLocationMatch) {
          question.showQuestion = true;
        } else {
          question.showQuestion = false;
        }
      }
    });
  }
  
  onNextClick() {
    if(this.questions){
        this.setShowQuestionsBasedOnAnonymousAndLocation(this.receivedAnonymousValue);
    }
    this.isRequired = true;
    let res;
    if (this.deviceService.isMobile()) {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("mobileNavs")
      );
    } else {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("webNavs")
      );
    }
    this.onTabNavigation(
      res.findIndex(x => x.className.includes("active")) + 1
    );
  }

  checkIfRequiredFieldsFilled(index) {
    if (index === 0 && this.selectedCategory) {
      this.appStateService.selectedCategory = this.selectedCategory;
      this.markTabComplete(index);
      return true;
    } else if (index === 1) {
      const generalInfo = this.commonService.generalInfo();
      const rePattern = new RegExp('.*\\S+.*');
      const re = new RegExp(
        AppConstants.EmailPattern
      );
      if (
        generalInfo.general.relation &&
        (this.cid === 'USCA' || generalInfo.general.communication) &&
        (!this.appStateService.requireLocation || generalInfo.location.name) &&
        (generalInfo.general.anonymous ||
          (generalInfo.userDetails &&
            ((!this.appStateService.showFirstName || !this.appStateService.requireFirstName) || rePattern.test(generalInfo.userDetails.firstName)) &&
            ((!this.appStateService.showLastName || !this.appStateService.requireLastName) || rePattern.test(generalInfo.userDetails.lastName)) &&
            ((!this.appStateService.showJobTitle || !this.appStateService.requireJobTitle) || rePattern.test(generalInfo.userDetails.jobTitle)) &&
            ((!this.appStateService.showAddress || !this.appStateService.requireAddress) || rePattern.test(generalInfo.userDetails.address)) &&
            ((!this.appStateService.showCity || !this.appStateService.requireCity) || rePattern.test(generalInfo.userDetails.city)) &&
            ((!this.appStateService.showState || !this.appStateService.requireState) || rePattern.test(generalInfo.userDetails.state)) &&
            ((!this.appStateService.showPostalCode || !this.appStateService.requirePostalCode) || rePattern.test(generalInfo.userDetails.postalCode)) &&
            ((this.cid !== 'USCA' && this.cid !== 'DEMO') || (!this.appStateService.showPhone || !this.appStateService.requirePhone) || rePattern.test(generalInfo.userDetails.phone)) &&
            (this.cid === 'ATT' || (this.appStateService.showEmail && re.test(generalInfo.userDetails.email) ) || (!this.appStateService.showEmail) || (!this.appStateService.requireEmail && generalInfo.userDetails.email.trim()== '') ) )))
       {
        if (generalInfo.general.anonymous) {
          if (generalInfo.general.anonymousEmail != null && generalInfo.general.anonymousEmail != "") {
            if (re.test(generalInfo.general.anonymousEmail)) {
              this.appStateService.generalDetails = generalInfo;
              this.markTabComplete(index);
              return true;
            }
          }
          else {
            this.appStateService.generalDetails = generalInfo;
            this.markTabComplete(index);
            return true;
          }
        }
        else {
          this.appStateService.generalDetails = generalInfo;
          this.markTabComplete(index);
          return true;
        }
      }
    } else if (index === 2) {
      const additionalInfo = this.commonService.AdditionalInfo();
      if ((this.appStateService.hideConcern || (!this.appStateService.hideConcern && additionalInfo.concern &&
        this.checkConcernLength(additionalInfo.concern.length))) &&
        this.checkReqQuestionsAnswered(additionalInfo.questions)) {
        {
          this.appStateService.additionalDetails = additionalInfo;
          this.appStateService.documents = this.appStateService.additionalDetails.documents;
          this.markTabComplete(index);
          return true;
        }
      }
    }
    this.unmarkTabComplete(index);
    return false;
  }

  checkConcernLength(length: any) {
    if (this.appStateService.hasBounds) {
      return (length >= this.appStateService.detailMin && length <= this.appStateService.detailMax)
    }
    return true;
  }

  checkReqQuestionsAnswered(ques) {
    let res = true;
    if (ques) {
      ques.forEach(element => {
        if (
          element.required === "Y" && element.showQuestion === true &&
          (!element.answer || element.answer === "")
        ) {
          res = false;
        }
      });
    }
    return res;
  }

  navigateToSpecificTab(value) {
    this.isSummary = false;
    setTimeout(() => {
      this.markTabComplete(0);
      this.markTabComplete(1);
      this.markTabComplete(2);
      let res;
      if (this.deviceService.isMobile()) {
        res = Array.prototype.slice.call(
          document.getElementsByClassName("mobileNavs")
        );
      } else {
        res = Array.prototype.slice.call(
          document.getElementsByClassName("webNavs")
        );
      }
      const content = Array.prototype.slice.call(
        document.getElementsByClassName("tab-pane")
      );
      if (res.find(x => x.className.includes("active"))) {
        const index = res.findIndex(x => x.className.includes("active"));
        if (index !== res.length - 1) {
          res[index].classList.remove("active");
          content[index].classList.remove("active");
        }
      }
      switch (value) {
        case "personalDetails": {
          res[1].classList.add("active");
          content[1].classList.add("active");
          this.createAudit(1);
          const myElement = document.getElementById("personalDetails");
          myElement.scrollIntoView();
          break;
        }
        case "report": {
          res[2].classList.add("active");
          content[2].classList.add("active");
          this.createAudit(2);
          break;
        }
        case "location": {
          res[1].classList.add("active");
          content[1].classList.add("active");
          this.createAudit(1);
          const myElement = document.getElementById("location");
          myElement.scrollIntoView();
          break;
        }
        case "subjects": {
          res[2].classList.add("active");
          content[2].classList.add("active");
          this.createAudit(2);
          const myElement = document.getElementById("subjects");
          myElement.scrollIntoView();
          break;
        }
        case "questions": {
          res[2].classList.add("active");
          content[2].classList.add("active");
          this.createAudit(2);
          const myElement = document.getElementById("questions");
          myElement.scrollIntoView();
          break;
        }
      }
      this.commonService.NotifyTabChange.next();
    }, 50);
  }

  private createAudit(tab) {
    this.commonService.createAudit(
      this.cid,
      "New Report - " + this.getTabName(tab),
      "/report"
    );
  }

  onTabNavigation(selectedTabIndex) {
    window.scroll(0, 0);
    let res;
    if (this.deviceService.isMobile()) {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("mobileNavs")
      );
    } else {
      res = Array.prototype.slice.call(
        document.getElementsByClassName("webNavs")
      );
    }
    const content = Array.prototype.slice.call(
      document.getElementsByClassName("tab-pane")
    );
    if (res.find(x => x.className.includes("active"))) {
      const index = res.findIndex(x => x.className.includes("active"));
      if (index !== selectedTabIndex) {
        if (
          index !== selectedTabIndex - 2 ||
          this.checkIfRequiredFieldsFilled(selectedTabIndex - 1)
        ) {
          if (
            selectedTabIndex >= 0 &&
            index !== selectedTabIndex &&
            (this.checkIfRequiredFieldsFilled(index) ||
              selectedTabIndex < index)
          ) {
            if (selectedTabIndex < res.length) {
              this.validateTabs(selectedTabIndex, index);
              res[index].classList.remove("active");
              content[index].classList.remove("active");
              res[selectedTabIndex].classList.add("active");
              content[selectedTabIndex].classList.add("active");
              this.commonService.NotifyTabChange.next();
              this.checkIfScrollbarExist();
            } else {
              this.isSummary = true;
            }
            this.commonService.createAudit(
              this.cid,
              "New Report - " + this.getTabName(selectedTabIndex),
              "/report"
            );
          } else {
            this.validateTabs(selectedTabIndex, index);
          }
        }
        this.validateTabs(selectedTabIndex, index);
      }
    }
    if (selectedTabIndex == 0) {
      this.checkIfScrollbarExist();
    }
  }

  getTabName(selectedTabIndex: any) {
    switch (selectedTabIndex) {
      case 0:
        return "Key Category";
      case 1:
        return "Basic Information";
      case 2:
        return "Additional Details";
      case 3:
        return "Summary Details";
    }
  }

  validateTabs(selectedTabIndex: any, index: any) {
    if (index === 0) {
      this.commonService.categoryInfo(true);
    } else if (selectedTabIndex - index === 1) {
      if (index === 1) {
        this.commonService.generalInfo(true);
      } else if (index === 2) {
        this.commonService.AdditionalInfo(true);
      }
    }
  }

  markTabComplete(index) {
    switch (index) {
      case 0:
        if (this.deviceService.isMobile()) {
          document
            .getElementById("categoryComplete")
            .classList.add("mobile-complete");
        } else {
          document.getElementById("keyCategory").classList.add("web-complete");
        }
        break;
      case 1:
        if (this.deviceService.isMobile()) {
          document
            .getElementById("basicComplete")
            .classList.add("mobile-complete");
        } else {
          document
            .getElementById("basicInformation")
            .classList.add("web-complete");
        }
        break;
      case 2:
        if (this.deviceService.isMobile()) {
          document
            .getElementById("additionalComplete")
            .classList.add("mobile-complete");
        } else {
          document
            .getElementById("additionalDetails")
            .classList.add("web-complete");
        }
        break;
    }
  }

  unmarkTabComplete(index) {
    switch (index) {
      case 0:
        if (this.deviceService.isMobile()) {
          document
            .getElementById("categoryComplete")
            .classList.remove("mobile-complete");
        } else {
          document
            .getElementById("keyCategory")
            .classList.remove("web-complete");
        }
        break;
      case 1:
        if (this.deviceService.isMobile()) {
          document
            .getElementById("basicComplete")
            .classList.remove("mobile-complete");
        } else {
          document
            .getElementById("basicInformation")
            .classList.remove("web-complete");
        }
        break;
      case 2:
        if (this.deviceService.isMobile()) {
          document
            .getElementById("additionalComplete")
            .classList.remove("mobile-complete");
        } else {
          document
            .getElementById("additionalDetails")
            .classList.remove("web-complete");
        }
        break;
    }
  }

  onReportNav() {
    let res;
    if (this.deviceService.isMobile()) {
      res = document.getElementById("mobile-complete");
    } else {
      res = document.getElementById("web-complete");
    }
    if (this.appStateService.selectedCategory || res) {
      this.appStateService.generalDetails = this.commonService.generalInfo();
      this.appStateService.additionalDetails = this.commonService.AdditionalInfo();
      this.appStateService.documents = this.appStateService.additionalDetails.documents;
    }
    this.ReportNav.emit();
  }

  checkIfScrollbarExist() {
    if (this.deviceService.isDesktop()) {
      let hasScrollbar =
        document.body.scrollHeight > document.documentElement.clientHeight;
      if (hasScrollbar) {
        this.isShowFooter = false;
      } else {
        this.isShowFooter = true;
        this.commonService.NotifyTabChange.next();
      }
    }
  }
  GetKeySurveyConfigData() {
    this.commonService.getMcrConfigDetailsByConfigId(
      this.cid,
      this.appStateService.language,
      res => {
        if (res) {
          this.isKeySurveyForm = res.keySurveyForm;
          this.keySurveyFormURL = this.sanitizeURL(res.keySurveyFormURL);
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  sanitizeURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  @HostListener("window:scroll", ["$event"])
  public onScroll(event): void {
    if (this.deviceService.isDesktop()) {
      var scrollValue = window.pageYOffset;
      const hasScrollbar =
        document.body.scrollHeight > document.documentElement.clientHeight;
      if (hasScrollbar) {
        if (scrollValue > 50) {
          this.isShowFooter = true;
          setTimeout(() => {
            this.commonService.NotifyTabChange.next();
          }, 2);
        } else {
          this.isShowFooter = false;
        }
      } else if (!hasScrollbar) {
        this.isShowFooter = true;
        this.commonService.NotifyTabChange.next();
      }
    }
  }
}