import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  Input,
  ViewEncapsulation
} from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AppStateService } from "../appStateService";
import { CommonService } from "../common.service";
import { LocationSearch } from "../Models/LocationSearch";

@Component({
  selector: "app-browse-locations-modal",
  templateUrl: "./browse-locations-modal.component.html",
  encapsulation: ViewEncapsulation.None,

  styleUrls: ["./browse-locations-modal.component.scss"]
})
export class BrowseLocationsModalComponent implements OnInit {
  filterCity: boolean;
  filterState: boolean;
  filterCountry: boolean;
  isOpenAddFilter = false;
  searchString = "";
  filterString = "";
  selectedLocation: string;
  searchFields: any;
  filteredCountries: any = [];
  filteredStates: any = [];
  filteredCities: any = [];
  scrollIndex: number = 1;
  infiniteScrollDisabled: boolean = false;
  locations: any;
  timeout: any = 0;
  pageSize = 10;
  @Input() proxyForm: boolean = false;
  @Output() searched = new EventEmitter<string>();
  // @Input() locations: any;
  @Output() selectedLocationOutput: EventEmitter<any> = new EventEmitter();
  filterFields = {
    city: "",
    state: "",
    country: ""
  };
  unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  constructor(
    public activeModal: NgbActiveModal,
    public commonService: CommonService,
    public appStateService: AppStateService,
    public toastr: ToastrService
  ) { }
  ngOnInit() {
    this.filterFields = {
      city: "",
      state: "",
      country: ""
    };
    this.selectedLocation = "";

    this.getLocations();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedLocation = "";
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
  }

  removefilter() {
    this.clearFilters();
    this.isOpenAddFilter = false;
    this.filterString = "";
  }

  getChecked(value) {
    if (value) {
      this.selectedLocation = value;
    }
  }

  locationChange() {
    if (this.selectedLocation) {
      this.selectedLocationOutput.emit(this.selectedLocation);
      this.commonService.setSelectedLocation(this.selectedLocation);    }
    this.selectedLocation = "";
    this.activeModal.close(this.selectedLocation);
  }

  onSearchChange() {
    // this.searchFields = {
    //   name: this.searchString,
    //   address: this.searchString,
    //   city: this.searchString,
    //   state: this.searchString,
    //   country: this.searchString,
    //   postalCode: this.searchString
    // };
    this.refetchLocations(500);
  }

  search(term: string) {
    if (term.length >= 2) {
      this.getLocationFilterResult();
    } else {
      this.filteredCountries = [];
      this.filteredCities = [];
      this.filteredStates = [];
    }
    //document.getElementsByClassName("search-filters").re
    // if (term.length >= 2) {
    //   this.filteredCountries = this.locations
    //     .filter(
    //       v =>
    //         v.country && v.country.toLowerCase().startsWith(term.toLowerCase())
    //     )
    //     .map(x => x.country);
    //   this.filteredCountries = this.filteredCountries.filter(this.unique);
    //   this.filteredCities = this.locations
    //     .filter(
    //       v => v.city && v.city.toLowerCase().startsWith(term.toLowerCase())
    //     )
    //     .map(x => x.city);
    //   this.filteredCities = this.filteredCities.filter(this.unique);
    //   this.filteredStates = this.locations
    //     .filter(
    //       v => v.state && v.state.toLowerCase().startsWith(term.toLowerCase())
    //     )
    //     .map(x => x.state);
    //   this.filteredStates = this.filteredStates.filter(this.unique);
    // } else {
    //   this.filteredCountries = [];
    //   this.filteredCities = [];
    //   this.filteredStates = [];
    // }
  }

  getResult(result) {
    if (result) {
      if (
        result.name &&
        result.name
          .toLowerCase()
          .indexOf(this.filterString.toLocaleLowerCase()) > -1
      ) {
        return result.name;
      } else if (
        result.city &&
        result.city
          .toLowerCase()
          .indexOf(this.filterString.toLocaleLowerCase()) > -1
      ) {
        return result.city;
      } else if (
        result.state &&
        result.state
          .toLowerCase()
          .indexOf(this.filterString.toLocaleLowerCase()) > -1
      ) {
        return result.state;
      }
    }
  }

  onSelection(type, value, filter = true) {
    this.filteredCountries = [];
    this.filteredCities = [];
    this.filteredStates = [];
    switch (type) {
      case "city": {
        this.filterFields = {
          city: value,
          state: this.filterFields.state,
          country: this.filterFields.country
        };
        this.filterCity = filter;
        break;
      }
      case "state": {
        this.filterFields = {
          state: value,
          city: this.filterFields.city,
          country: this.filterFields.country
        };
        this.filterState = filter;

        break;
      }
      case "country": {
        this.filterFields = {
          country: value,
          city: this.filterFields.city,
          state: this.filterFields.state
        };
        this.filterCountry = filter;
        break;
      }
    }
    this.refetchLocations(0);
    this.filterString = "";
    document.getElementById('addFilter').removeAttribute('placeholder');
  }
  clearFilters() {
    this.filterString = '';
    this.filterCity = false;
    this.filterState = false;
    this.filterCountry = false;
    this.filteredCountries = [];
    this.filteredCities = [];
    this.filteredStates = [];
    this.filterFields = {
      city: "",
      state: "",
      country: ""
    };
    document.getElementById('addFilter').setAttribute('placeholder', 'Type to filter City, State or Country');
    this.refetchLocations(0);
  }
  onSearch(searchTerm: string): void {
    this.searched.emit(searchTerm);
  }

  getLocations(): any {
    this.commonService.getLocations_Lazy(
      this.getLocationSearchObj(),
      data => {
        if (data && data.length > 0) {
          if (this.locations && this.locations.length > 0) {
            this.locations.push(...data);
          } else {
            this.locations = data;
          }
          this.locations = this.locations.slice();
        }
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  onScroll() {
    this.scrollIndex += 1;
    this.getLocations();
  }

  resetInfiniteScroll() {
    this.infiniteScrollDisabled = true;
    setTimeout(() => {
      this.infiniteScrollDisabled = false;
    }, 500);
  }

  refetchLocations(delay: number) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.scrollIndex = 1;
      this.resetInfiniteScroll();
      this.locations = null;
      this.getLocations();
    }, delay);
  }

  getLocationFilterResult() {
    this.commonService.getLocationFilterResult(
      this.appStateService.customerId,
      this.filterString,
      res => {
        this.filteredCities = res.cityList;
        this.filteredStates = res.stateList;
        this.filteredCountries = res.countryList;
      },
      err => {
        console.log("Error occurred while fetching location filter results.");
      }
    )
  }

  getLocationSearchObj() {
    let obj = new LocationSearch();
    return obj = {
      CustomerId: this.appStateService.customerId,
      ScrollIndex: this.scrollIndex,
      PageSize: this.pageSize,
      SearchString: this.searchString,
      City: this.filterFields.city,
      State: this.filterFields.state,
      Country: this.filterFields.country,
      IsTypeAhead: false,
    }
  }
}
