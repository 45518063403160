import {
  Component,
  Input,
  OnInit,
  ViewChildren,
  SimpleChanges,
  Output,
  EventEmitter
} from "@angular/core";
import { AppStateService } from "../appStateService";
import { CommonService } from "../common.service";

@Component({
  selector: "app-category-list",
  templateUrl: "./category-list.component.html",
  styleUrls: ["./category-list.component.scss"]
})
export class CategoryListComponent implements OnInit {
  private selectedIndex: Number;
  private loadComponent: boolean = false;
  searchText: string;
  searchColumns = ["name", "notes"];
  isContentMore = false;
  timeout: any = 0;
  @Input() categories: any;
  @Input() selectedCategory = null;
  @Input() categoriesErrorMessage: string;
  @Output() selectedCategoryOutput: EventEmitter<any> = new EventEmitter();
  @Output() searchChangeOutput: EventEmitter<any> = new EventEmitter();
  isRequired = false;
  showMessage = false;
  constructor(private commonService: CommonService, public appStateService: AppStateService) { }
  categoriesCount: any;
  ngOnInit() {
    this.commonService.categoryInfo = res => {
      return (this.isRequired = res);
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === "categories") {
        this.searchText = "";
        if (this.categories != undefined && (!this.categories || this.categories.length == 0)) {
          this.showMessage = true;
        } else {
          if (this.categories != undefined) {
            this.categoriesCount = this.categories.filter(element => {
              return element.key !== null && element.key !== undefined;
            })
          }
          this.showMessage = false;
        }
      }
    }
  }

  getChecked(value) {
    this.selectedCategory = value;
    this.selectedCategoryOutput.emit(value);
  }

  searchChange(categoryList) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (categoryList && categoryList.children && categoryList.children.length > 0) {
        this.searchChangeOutput.emit();
        // console.log("Change detected");
      }
    }, 100);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView();
    }
  }

  categoryHeaderClick(index) {
    setTimeout(() => {
      const element = document.querySelector('#categories' + index);
      this.scrollTo(element);
    }, 10)
  }
}
