import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  ElementRef,
  ViewChild
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { CommonService } from "../common.service";
import { debounceTime, map, distinctUntilChanged, switchMap } from "rxjs/operators";
import { AppStateService } from "../appStateService";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { BrowseLocationsModalComponent } from "../browse-locations-modal/browse-locations-modal.component";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "../shared/app-constants";

@Component({
  selector: "app-general-section",
  templateUrl: "./general-section.component.html",
  encapsulation: ViewEncapsulation.None,

  styleUrls: ["./general-section.component.scss"]
})
export class GeneralSectionComponent implements OnInit {
  isOpenLocationsModal: boolean = false;
  @Input() categories: any;
  @Input() selectedCategory: any;
  @Output() selectedCategoryOutput: EventEmitter<any> = new EventEmitter();
  searchText: string;
  selectedCategorySubscription: Subscription;
  generalForm: any;
  isAnonymous: boolean = true;
  userForm: any;
  locationForm: any;
  selectedRelation: any;
  selectedCommunication: any;
  selectedCountry: any;
  typeAheadData: any;
  locations: any = [];
  cid: any;
  warningMessage: any;
  isRequired: boolean;
  @ViewChildren("typeaheadInstance") typeaheadInstance: any;
  @ViewChild("uid", { static: false }) uid: ElementRef;
  countries = [];
  unique = (value, index, self) => {
    if (value !== "" && value !== " " && value !== null) {
      return self.indexOf(value) === index;
    }
  };
  isUserDetailsRequired: any;
  isRepAttrPresent: boolean = true;

  constructor(
    public fb: FormBuilder,
    private commonService: CommonService,
    public appStateService: AppStateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.isRequired = false;
    this.route.queryParams.subscribe(params => {
      this.cid = params.cid;
    });

    let firstTimeIssueFiled = "yes";
    if ( !this.appStateService.HideFirstReportQuestion ) {
      firstTimeIssueFiled = "no";
    }

    this.generalForm = this.fb.group({
      firstTime: [firstTimeIssueFiled, [Validators.required]],
      relation: ["", [Validators.required]],
      anonymous: [true, [Validators.required]],
      anonymousEmail: [
        "",
        [
          Validators.pattern(
            AppConstants.EmailPattern
          )
        ]
      ],
      communication: ["", [Validators.required]]
    });

    this.generalForm.get('anonymous').valueChanges.subscribe((value: boolean) => {
      this.appStateService.showAnonymousValue = value; 
    });
    
    this.locationForm = this.fb.group({
      locationID: [0, [Validators.required]],
      nameID: ["", [Validators.required]],
      name: ["", [Validators.required]],
      address: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
      country: ["", [Validators.required]],
      postalCode: ["", [Validators.required]],
      level_1: ["", [Validators.required]],
      level_2: ["", [Validators.required]],
      level_3: ["", [Validators.required]],
      level_4: ["", [Validators.required]],
      level_5: ["", [Validators.required]],
      level_6: ["", [Validators.required]],
      level_7: ["", [Validators.required]],
      level_8: ["", [Validators.required]]
    });
    this.userForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      lastName: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      jobTitle: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      address: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      city: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      state: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      postalCode: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      phone: ["", [Validators.required, Validators.pattern('.*\\S+.*')]],
      employeeUID: [""],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            AppConstants.EmailPattern
          )
        ]
      ]
    });
    this.appStateService.languageSub.subscribe(sub => {
      this.changeRelation("");
      this.changeCommunication("");
      this.getAnonymousDetails();
    });
    this.commonService.generalInfo = res => {
      return this.generalInfoChange(res);
    };
    this.selectedRelation = "";
    this.selectedCommunication = "";
    this.selectedCountry = "";
    if (this.appStateService.generalDetails) {
      this.setGeneralInfo(this.appStateService.generalDetails.general);
      if (this.appStateService.generalDetails.userDetails) {
        this.setUserInfo(this.appStateService.generalDetails.userDetails);
      }
      if (this.appStateService.generalDetails.location) {
        this.onLocationChange(this.appStateService.generalDetails.location);
      }
      this.warningMessage = this.appStateService.generalDetails.warningMessage;
    } else {
      this.getAnonymousDetails();
    }
    // this.getLocations();
    if (!this.appStateService.lockLocation) {
      this.getCountries();
    }
  }

  getCountries() {
    this.commonService.getCountries(
      this.cid,
      'en-US',
      res => {
        this.countries = res;
      },
      err => {
        console.log("Error occurred while fetching countries for customer " + this.cid);
      }
    )
  }

  setUserInfo(userDetails) {
    if (userDetails) {
      this.userForm.patchValue({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        jobTitle: userDetails.title ? userDetails.title : (userDetails.jobTitle ? userDetails.jobTitle : ''),
        address: userDetails.address,
        city: userDetails.city,
        state: userDetails.state,
        postalCode: userDetails.postalCode,
        phone: userDetails.phone ? userDetails.phone : (userDetails.phone_Home ? userDetails.phone_Home : ''),
        email: userDetails.email,
        employeeUID: userDetails.uid ? userDetails.uid : (userDetails.employeeUID ? userDetails.employeeUID : (userDetails.attuid ? userDetails.attuid : ''))
      });
    }
    else {
      this.userForm.reset();
    }
    if (this.uid) {
      this.uid.nativeElement.value = this.userForm.value.employeeUID;
    }
  }

  setEmployeeInfo(userDetails) {
    this.userForm.patchValue({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      jobTitle: userDetails.title ? userDetails.title : (userDetails.jobTitle ? userDetails.jobTitle : ''),
      address: userDetails.address,
      city: userDetails.city,
      state: userDetails.state,
      postalCode: userDetails.postalCode,
      phone: userDetails.phone_Home,
      email: userDetails.email,
      employeeUID: userDetails.uid ? userDetails.uid : (userDetails.employeeUID ? userDetails.userDetails.employeeUID : '')
    });
  }

  setGeneralInfo(details) {
    this.generalForm.patchValue({
      firstTime: details.firstTime,
      relation: details.relation,
      anonymous: details.anonymous,
      communication: details.communication
    });
    this.selectedRelation = details.relation;
    this.selectedCommunication = details.communication;
  }

  getAnonymousDetails() {
    this.commonService.getAnonymousDetails(
      this.cid,
      this.appStateService.language,
      this.appStateService.countryId,
      res => {
        this.setAnonymous(res);
      },
      err => {
        this.showError(err);
      }
    );
  }

  showError(err: any) {
    this.toastr.error(err.message);
  }

  setAnonymous(res): any {

    this.isRepAttrPresent = this.appStateService.showFirstName || this.appStateService.showLastName
      || this.appStateService.showJobTitle || this.appStateService.showAddress
      || this.appStateService.showCity || this.appStateService.showState
      || this.appStateService.showPostalCode || this.appStateService.showPhone
      || this.appStateService.showEmail;

    this.generalForm.patchValue({
      anonymous: (res.status && res.status === "Y") || !this.isRepAttrPresent ? true : false
    });
    if (res.status == "N") {
      this.isAnonymous = false;
    }
    this.warningMessage = res.warningMessage;
    this.appStateService.showAnonymous = res.showOptions;
  }

  generalInfoChange(validate = null) {
    this.isRequired = validate;
    this.isUserDetailsRequired = validate && !this.generalForm.value.anonymous;
    if (!validate) {
      return {
        userDetails: this.generalForm.value.anonymous
          ? null
          : this.userForm.value,
        general: this.generalForm.value,
        location: this.locationForm.value,
        warningMessage: this.warningMessage
      };
    }
  }

  getLocations(): any {
    this.commonService.getLocations(
      this.appStateService.customerId,
      data => {
        if (data) {
          this.locations = data;
          this.countries = this.locations.map(x => x.country)
            ? this.locations.map(x => x.country).filter(this.unique)
            : null;
        }
      },
      err => {
        this.showError(err);
      }
    );
  }

  browseLocations() {
    this.resetLocationSearch();
    const modalRef = this.modalService.open(BrowseLocationsModalComponent, {
      windowClass: "modal-window",
      backdrop: "static"
    });
    //input
    // modalRef.componentInstance.locations = this.locations;
    //output
    modalRef.componentInstance.selectedLocationOutput.subscribe(location => {
      this.onLocationChange(location);
    });
  }

  getChecked(value) {
    this.selectedCategory = value;
    this.selectedCategoryOutput.emit(value);
    document.getElementById("dropdownCategory").scroll(0, 0);
  }
  formatResult(result: any): string {  
    let formattedString = '';  
    if (result.name) {
      formattedString += result.name;
    }
    if (result.address) {
      formattedString += ', ' + result.address;
    }
    if (result.city) {
      formattedString += ', ' + result.city;
    }
    if (result.country) {
      formattedString += ', ' + result.country;
    }
    if (result.postalCode) {
      formattedString += ', ' + result.postalCode;
    }
    formattedString = formattedString.replace(/^,\s*|\s*$/g, '').trim();
    return formattedString;
  }
  onLocationChange(location) {
    this.locationForm.patchValue({
      address: location.address,
      city: location.city,
      state: location.state,
      country: location.country,
      postalCode: location.postalCode,
      name: location.name,
      locationID: +location.locationID,
      nameID: location.nameID,
      level_1: location.level_1,
      level_2: location.level_2,
      level_3: location.level_3,
      level_4: location.level_4,
      level_5: location.level_5,
      level_6: location.level_6,
      level_7: location.level_7,
      level_8: location.level_8
    });
    this.selectedCountry = location.country;
  }

  resetLocations() {
    if (this.cid !== 'CYH') {
      this.resetLocationSearch();
    }
    this.selectedCountry = "";
    this.locationForm.patchValue({
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      name: "",
      locationID: 0,
      nameID: "",
      level_1: "",
      level_2: "",
      level_3: "",
      level_4: "",
      level_5: "",
      level_6: "",
      level_7: "",
      level_8: ""
    });
  }

  private resetLocationSearch() {
    const ele = document.getElementById("locationSearch") as HTMLInputElement;
    ele.value = "";
  }

  setDropdown() {
    this.searchText = "";
    document.getElementById("categoryDropdown").scroll(0, 0);
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(1),
      distinctUntilChanged(),
      switchMap(searchText => this.data(searchText))
    );
  };

  data(searchText) {
    if (searchText.trim().length < 3) {
      if (this.typeaheadInstance) {
        var instance = this.typeaheadInstance._results.find(x =>
          x.isPopupOpen()
        );
        if (instance) {
          instance.dismissPopup();
        }
      }
      return [];
    }

    return this.commonService.getLocationsForTypeahead(this.cid, searchText, true)
      .pipe(map(item => item))
  }

  formatter = (x: any) => {
    let formattedString = '';
  
    // Concatenate non-empty fields with commas
    if (x.name) {
      formattedString += x.name;
    }
  
    if (x.address) {
      if (formattedString) {
        formattedString += ', ';
      }
      formattedString += x.address;
    }
  
    if (x.city) {
      if (formattedString) {
        formattedString += ', ';
      }
      formattedString += x.city;
    }
  
    if (x.country) {
      if (formattedString) {
        formattedString += ', ';
      }
      formattedString += x.country;
    }
  
    if (x.postalCode) {
      if (formattedString) {
        formattedString += ', ';
      }
      formattedString += x.postalCode;
    }
  
    return formattedString;
  };

  changeRelation(value) {
    this.selectedRelation = value;
    this.generalForm.patchValue({
      relation: value
    });
    if (this.appStateService.generalDetails) {
      this.appStateService.generalDetails.general.relation = value;
    }
    if (value) {
      document.getElementById("relation").scroll(0, 0);
    }
  }

  changeCommunication(value) {
    this.selectedCommunication = value;
    this.generalForm.patchValue({
      communication: value
    });
    if (this.appStateService.generalDetails) {
      this.appStateService.generalDetails.general.communication = value;
    }
    if (value) {
      document.getElementById("communication").scroll(0, 0);
    }
  }

  changeCountry(value) {
    this.selectedCountry = value;
    this.locationForm.patchValue({
      country: value
    });
  }

  get userControl() {
    return this.userForm.controls;
  }
  get generalControl() {
    return this.generalForm.controls;
  }
  numericOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode >= 48 && charCode <= 57) ||
      charCode === 32 ||
      charCode === 43
    ) {
      return true;
    }
    return false;
  }

  getUser(uid) {
    if (uid && uid.value) {
      if (this.cid == 'ATT') {
        this.getAttUser(uid);
      }
      else {
        this.getEmployee(uid);
      }
    }
  }

  getEmployee(uid) {
    this.commonService.getEmployee(this.cid, uid.value, res => { this.setUserInfo(res); }, err => this.getUserError(err));
  }

  getAttUser(attUid) {
    this.commonService.getAttUser(this.cid, attUid.value, res => this.getAttUserSuccess(res), err => this.getUserError(err));
  }

  getAttUserSuccess(res) {
    this.setUserInfo(res);
  }

  getUserError(err) {
    this.toastr.error(err);
  }

  public showDropdownEle(event) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (event.target.nextElementSibling) {
        let activeElement = event.target.nextElementSibling.querySelector('.active');
        let activeDropdownEle = (event.key === 'ArrowDown') ?
          (activeElement ? activeElement.nextElementSibling : null) :
          (activeElement ? activeElement.previousElementSibling : null);
        if (!activeDropdownEle) {
          const allDropdownElems = event.target.nextElementSibling.querySelectorAll('.dropdown-item');
          activeDropdownEle = (event.key === 'ArrowUp') ? allDropdownElems[allDropdownElems.length - 1] : allDropdownElems[0];
        }
        activeDropdownEle.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }
  
  isElementInViewport(el, inputElem) {
    const rect = el.getBoundingClientRect();
    const rectElem = inputElem.getBoundingClientRect();
    // console.log(rectElem);
    return (
      rect.top >= rectElem.bottom &&
      rect.left >= 0 &&
      rect.bottom <= (rectElem.bottom + rect.offsetHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
