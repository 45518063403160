import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from 'src/app/appStateService';
import { CommonService } from 'src/app/common.service';
import { AppConstants } from 'src/app/shared/app-constants';
import { FaqComponent } from 'src/app/static/faq/faq.component';
import { TermsAndPoliciesComponent } from 'src/app/static/terms-and-policies/terms-and-policies.component';

@Component({
  selector: 'app-follow-up-landing',
  templateUrl: './follow-up-landing.component.html',
  styleUrls: ['./follow-up-landing.component.scss']
})
export class FollowUpLandingComponent implements OnInit {
  isLanding = true;
  isValid = false;
  isFollowUp: boolean;
  loginForm: any;
  followupForm: any;
  isLoading = false;
  isLimitExceeded: boolean;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private appStateService: AppStateService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toastr: ToastrService,
  ) { 
    router.events.subscribe((event: any) => {
      if (event.url) {
        if (event.url === "/followUp") {
          this.isLanding = false;
        } else {
          this.isLanding = true;
        }
      }
    });
  }

  ngOnInit() {
    if (!sessionStorage.getItem("session")) {
      sessionStorage.setItem("session", Guid.create().toString());
    }
    if (this.isLanding) {
      this.createAudit("Client Select", "/MCR");
    } else {
      this.createAudit("FollowUp - Login", "/followUp");
    }
    this.loginForm = this.fb.group({
      CustomerId: [""]
    });
    this.followupForm = this.fb.group({
      Date: [""],
      CustomerId: [""],
      CallNum: [""],
      Password: [""]
    });
  }

  createReport() {
    this.isValid = false;
    this.isLoading = true;
    this.loginForm.get('CustomerId').setValue(this.loginForm.value.CustomerId.trim().replace(/\s+/g, ''));
    // future improvement, use a constant dictionary or a map to avoid a growing switch statement
    switch (this.loginForm.value.CustomerId.toLowerCase().trim()) {
      case "938259":
        this.loginForm.value.CustomerId = "CREE";
        break;
      case "partners":
        this.loginForm.value.CustomerId = "PHC";
        break;
      case "egs":
        this.loginForm.value.CustomerId = "NCO";
        break;
      case "apac":
        this.loginForm.value.CustomerId = "NCO";
        break;
      case "upmc":
        this.loginForm.value.CustomerId = "UPP";
        break;
      case "TGTHR":
        this.loginForm.value.CustomerId = "TGTHR";
        break;
      default:
        this.loginForm.value.CustomerId = this.loginForm.value.CustomerId;
        break;
    }
    this.commonService.GetIssuesCount(this.loginForm.value.CustomerId,
      (res) => {
        if (res >= AppConstants.MCRReportMaxLimit) {
          this.isLimitExceeded = true;
          this.isLoading = false;
        } else {
          this.getCustomerDetails();
        }
      },
      err => {
        this.isLoading = false;
      })
  }

  private getCustomerDetails() {
    this.commonService.GetCustomerById(
      this.loginForm.value.CustomerId,
      data => {
        if (this.loginForm.value.CustomerId == "TGTHR") {
          this.appStateService.mcrCallerType = data.pCallerType
            ? data.pCallerType.split(/[\r\n,\r,\n]/).filter(v => v !== "" && v !== "\r")
            : null;
          this.appStateService.mcrValidity = data.pValidity
            ? data.pValidity.split(/[\r\n,\r,\n]/).filter(v => v !== "" && v !== "\r")
            : null;
          this.appStateService.subjectTypes = data.pSubjectType
            ? data.pSubjectType.split(/[\r\n,\r,\n]/).filter(v => v !== "" && v !== "\r")
            : null;

          this.router.navigate(["/form"], {
            queryParams: { cid: this.loginForm.value.CustomerId }
          });
        }
        else {
          this.getLanguages(data);
        }
      },
      err => {
        this.isValid = true;
        this.isLoading = false;
      }
    );
  }

  private setMCRConfigDetails(res: any) {
    sessionStorage.setItem("customerId", this.loginForm.value.CustomerId);
    sessionStorage.setItem("customerName", res.name);
    this.appStateService.customerId = this.loginForm.value.CustomerId;
    this.appStateService.customerName = res.name;
  }

  showError(err: any) {
    this.toastr.error(err.message);
  }

  getLanguages(data) {
    this.commonService.getLanguages(
      this.loginForm.value.CustomerId,
      res => {
        if (res.length > 0) {
          this.setMCRConfigDetails(data);
          this.appStateService.languages = res;
          var lan = res.find(
            x => x.languageCD === this.appStateService.language
          )
            ? res.find(x => x.languageCD === this.appStateService.language)
            : res.find(x => x.languageCD === "en-US");
          if (lan) {
            this.appStateService.language = lan.languageCD;
          } else {
            this.appStateService.language = res[0].languageCD;
            this.appStateService.languageCd.next(res[0]);
          }
          this.translateService.use(
            this.appStateService.language.split("-")[0]
          );
          this.convertDirection();
          this.router.navigate(["/report"], {
            queryParams: { cid: this.loginForm.value.CustomerId }
          });
          this.createAudit(
            "New Report - Disclaimer",
            "/report",
            this.loginForm.value.CustomerId
          );

        } else {
          this.toastr.error("No MCR Configs found for this customer.");
        }
        this.isLoading = false;
      },
      err => {
        this.showError(err);
        this.isLoading = false;
      }
    );
  }

  followUpReport() {
    this.isValid = false;
    if (
      this.followupForm.value.CustomerId &&
      this.followupForm.value.Date &&
      this.followupForm.value.CallNum &&
      this.followupForm.value.Password
    ) {
      this.isLoading = true;
      const followup =
        this.followupForm.value.Date +
        "-" +
        this.followupForm.value.CustomerId +
        "-" +
        this.followupForm.value.CallNum;
      const crs = followup + "-01";

      this.commonService.GetIssuesCount(this.followupForm.value.CustomerId,
        (res) => {
          if (res >= AppConstants.MCRReportMaxLimit) {
            this.isLimitExceeded = true;
            this.isLoading = false;
          } else {
            this.validateIssue(crs, followup);
          }
        },
        err => {
          this.isLoading = false;
        });
    } else {
      this.isValid = true;
    }
  }

  private validateIssue(crs: string, followup: string) {
    this.commonService.validateIssue(
      crs,
      this.followupForm.value.Password,

      data => {
        if (!data) {
          this.appStateService.reportId = crs;
          this.appStateService.customerId = this.followupForm.value.CustomerId;
          this.router.navigate(["/followUp-report"]);
          this.createAudit(
            "FollowUp - Show Resolution " + followup,
            "/followUp-report",
            this.followupForm.value.CustomerId
          );
        } else {
          if (data == 'Error') {
            this.isValid = true;
          }
          else {
            this.translateService.get(data).subscribe(res => {
              this.toastr.error(res);
            });
          }
        }
        this.isLoading = false;
      },
      err => {
        this.isValid = true;
        this.isLoading = false;
      }
    );
  }

  openFaqModal() {
    this.modalService.open(FaqComponent, { size: "xl", backdrop: "static" });
  }

  termsPolicies() {
    this.modalService.open(TermsAndPoliciesComponent, {
      size: "xl"
    });
  }
  convertDirection() {
    if (this.appStateService.language == "ar-AE") {
      document.body.className = "change-direction";
      document.body.style.direction = "rtl";
    } else {
      document.body.className = "ltr";
      document.body.style.direction = "ltr";
    }
  }

  changeDateFocus(event) {
    if (
      !this.ReplaceSelection(event) &&
      event.keyCode !== 8 &&
      event.keyCode !== 39 &&
      event.keyCode !== 37 &&
      event.keyCode !== 46 &&
      event.keyCode !== 9
    ) {
      let nextInput = event.srcElement.nextElementSibling;
      if (event.target.value.length == event.target.getAttribute("maxlength")) {
        nextInput.focus();
        return true;
      }
    }
    return !((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 105));
  }

  changeCidFocus(event) {
    if (!this.ReplaceSelection(event)) {
      if (
        event.keyCode !== 8 &&
        event.keyCode !== 39 &&
        event.keyCode !== 37 &&
        event.keyCode !== 46 &&
        event.keyCode !== 9
      ) {
        let nextInput = event.srcElement.nextElementSibling;
        if (
          event.target.value.length == event.target.getAttribute("maxlength")
        ) {
          nextInput.focus();
          return !((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 105));
        }
      } else if (event.keyCode === 8) {
        if (event.target.selectionStart === 0) {
          let prevInput = event.srcElement.previousElementSibling;
          prevInput.focus();
        }
      }
    }
  }

  changeCallNumFocus(event) {
    if (!this.ReplaceSelection(event) && event.keyCode === 8) {
      let prevInput = event.srcElement.previousElementSibling;
      if (event.target.selectionStart === 0) {
        prevInput.focus();
      }
    }
    return !((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 105));
  }

  ReplaceSelection(event) {
    var selectionStart = event.currentTarget.selectionStart;
    var selectionEnd = event.currentTarget.selectionEnd;
    if (selectionStart !== selectionEnd) {
      return true;
    }
    return false;
  }

  onCustomerIdChange() {
    this.loginForm.patchValue({
      CustomerId: this.loginForm.value.CustomerId.toUpperCase()
    });
  }

  onFollowCustomerIdChange() {
    this.followupForm.patchValue({
      CustomerId: this.followupForm.value.CustomerId.toUpperCase()
    });
  }

  createAudit(form, url, custId = null) {
    this.commonService.createAudit(custId, form, url);
  }

}
