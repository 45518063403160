import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renr-disclaimer',
  templateUrl: './renr-disclaimer.component.html',
  styleUrls: ['./renr-disclaimer.component.scss']
})
export class RenrDisclaimerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
