import { Pipe, PipeTransform } from "@angular/core";
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'

@Pipe({
  name: 'highlight',
})
export class HighlightSearch implements PipeTransform {
    transform(text: string, search: string) {
      return search ? text.replace(new RegExp('(' + search + ')', 'gi'), '<span class="highlight">$1</span>') : text;
    }

}