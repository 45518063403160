import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { AppStateService } from "./appStateService";
import { LocationSearch } from "./Models/LocationSearch";
import { AppConstants } from "./shared/app-constants";
import { v4 as uuidv4 } from 'uuid';

export class APIInput {
  CustomerId: string;
  LanguageCd: string;
  CountryId: number;
  CategoryId: number;
  CRSId: string;
  Password: string;
  Report: any;
  ResolutionText: string;
  HasResolutions: boolean;
  EmployeeUID: string;
  SurveyID: string;
  RespondentsID: string;
}

export class DeleteFileInput {
  UniqueId: string;
  FileName: string;
}

export class AuditRecord {
  CustID: string;
  Form: string;
  URL: string;
  SessionId: string;
}

@Injectable()
export class CommonService {
  categoryInfo: any;
  generalInfo: any;
  AdditionalInfo: any;
  SubjectsInfo: any;
  fetchCountries: any;
  NotifyTabChange = new Subject<any>();
  private baseUrl = this.appStateService.getBaseUrl();
  private apiInput = new APIInput();
  sessionId: string;
  SelectedLocationChange = new Subject<any>();
  selectedFiles: { file: File, fileName:string, isSuccess:boolean, description: string, uniqueIdentifier?: string; errorMessage: string }[] = [];

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {
    this.sessionId = sessionStorage.getItem("session");
  }

  setSelectedLocation(location: any) {
    this.SelectedLocationChange.next(location);
  }

  GetCustomerById(
    customerId: string,
    successcallback: Function,
    errorcallback: Function
  ) {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    return this.http
      .post(this.baseUrl + "auth/validate", this.apiInput)
      .subscribe(
        (res: any) => {
          successcallback(res);
        },
        error => {
          errorcallback(error);
        }
      );
  }

  GetCategories(
    customerId: string,
    language: string,
    countryId: number,
    successcallback: Function,
    errorcallback: Function
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.LanguageCd = language;
    this.apiInput.CountryId = countryId;
    return this.http
      .post(this.baseUrl + "MCR/categories", this.apiInput)
      .subscribe(
        (res: any) => {
          successcallback(res);
        },
        error => errorcallback(error)
      );
  }

  public getMcrConfigDetailsByConfigId(
    customerId,
    language,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.LanguageCd = language;
    return this.http.post(this.baseUrl + "MCR/config", this.apiInput).subscribe(
      (res: any) => {
        mcrSuccessCallback(res.responseData);
      },
      error => {
        mcrErrorCallback(error);
      }
    );
  }

  public getLanguages(
    customerId,
    successCallback,
    errorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    return this.http
      .post(this.baseUrl + "MCR/languages", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res.responseData);
        },
        error => {
          errorCallback(error);
        }
      );
  }

  public getCountries(
    customerId,
    language,
    successCallback,
    errorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.LanguageCd = language ? language : 'en-US';
    return this.http
      .post(this.baseUrl + "MCR/countries", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res.responseData);
        },
        error => {
          errorCallback(error);
        }
      );
  }

  public getLocations(
    customerId,
    successCallback,
    errorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;

    return this.http
      .post(this.baseUrl + "MCR/locations", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res.responseData);
        },
        error => {
          errorCallback(error);
        }
      );
  }

  public getLocations_Lazy(
    locationSearchObj,
    successCallback,
    errorCallback
  ): any {
    return this.http
      .post(this.baseUrl + "MCR/locations/lazyload", locationSearchObj)
      .subscribe(
        (res: any) => {
          successCallback(res);
        },
        error => {
          errorCallback(error);
        }
      );
  }

  public getLocationsForTypeahead(customerId, searchStr, isTypeAhead): any {
    let loc = new LocationSearch();
    loc.CustomerId = customerId;
    loc.SearchString = searchStr;
    loc.IsTypeAhead = isTypeAhead;
    return this.http.post(this.baseUrl + "MCR/locations/lazyload", loc);
  }

  public validateIssue(
    crsId,
    password,
    successCallback,
    errorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CRSId = crsId;
    this.apiInput.Password = password;
    return this.http
      .post(this.baseUrl + "followUp/validateissue", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res.responseData);
        },
        error => {
          errorCallback(error);
        }
      );
  }

  public getAnonymousDetails(
    customerId,
    language,
    countryId,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.LanguageCd = language;
    this.apiInput.CountryId = countryId;
    return this.http
      .post(this.baseUrl + "MCR/anonymous", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  public getQuestions(
    customerId,
    language,
    categoryId,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.LanguageCd = language;
    this.apiInput.CategoryId = categoryId;
    return this.http
      .post(this.baseUrl + "MCR/questions", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  GetQuestionRules(
    customerId: string,
    successcallback: Function,
    errorcallback: Function
  ) {
    return this.http
      .get(this.baseUrl + "MCR/questionrules/" + customerId)
      .subscribe(
        questions => {
          successcallback(questions);
        },
        error => {
          console.log("Some error occurred while getting data");
          errorcallback(error);
        }
      );
  }


  public saveReport(
    reportDetails,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    return this.http.post(this.baseUrl + "MCR/save", reportDetails).subscribe(
      (res: any) => {
        mcrSuccessCallback(res.responseData);
      },
      error => {
        mcrErrorCallback(error);
      }
    );
  }

  public getResolutions(
    crsId,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CRSId = crsId;
    return this.http
      .post(this.baseUrl + "followUp/resolutions", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  public updateFollowup(
    crsId,
    additionalInfo,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CRSId = crsId;
    this.apiInput.ResolutionText = additionalInfo;
    return this.http
      .post(this.baseUrl + "followUp/update", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  public addFollowup(
    cid,
    crsId,
    hasResolutions,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = cid;
    this.apiInput.CRSId = crsId;
    this.apiInput.HasResolutions = hasResolutions;
    return this.http
      .post(this.baseUrl + "followUp/add", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  public uploadDocuments(
    crsId,
    documents,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    const formData: FormData = new FormData();
    var fileDescriptionInfo = [];
    documents.forEach(doc => {
      fileDescriptionInfo.push({"FileName": doc.fileName, "Description": doc.description});
    });

    formData.append("descriptions", JSON.stringify({filedesc: fileDescriptionInfo}));
    formData.append("crsId", crsId);
    formData.append("customerId", this.appStateService.customerId);
    formData.append("uuid", sessionStorage.getItem("UUID"));

    return this.http.post(this.baseUrl + "followUp/saveDocuments", formData).subscribe(
      (res: any) => {
        // after a successful upload, remove files from array
        this.selectedFiles = [];
        mcrSuccessCallback(res.responseData);
        sessionStorage.removeItem('UUID'); //Remove document unique id once document moved to CRSId folder
      },
      error => {
        sessionStorage.removeItem('UUID');
        mcrErrorCallback(error);
      }
    );
  }


  public verifyUploadedFiles(
    documents: any,
    uuid: string,
    mcrSuccessCallback: (responseData: any) => void,
    mcrErrorCallback: (error: any) => void
  ): any {
    const formData: FormData = new FormData();

    const documentsArray = Array.isArray(documents) ? documents : [documents];

    documentsArray.forEach(doc => {
      formData.append("files", doc.file);
      formData.append("descriptions", doc.description);
    });
    formData.append("uuid", uuid);
    formData.append("customerId", this.appStateService.customerId);
    return this.http.post(this.baseUrl + "followUp/validateDocuments", formData).subscribe(
      (res: any) => {
        
        if(res && res.fileUploadResults) {
          res.fileUploadResults.forEach((file, i) => {
            file['description'] = documentsArray && documentsArray.length>i ? documentsArray[i].description : '';
          });

        }
        mcrSuccessCallback(res);
      },
      (error: any) => {
        mcrErrorCallback(error);
      }
    );
  }

  public getStatus(
    cid,
    crsId,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = cid;
    this.apiInput.CRSId = crsId;
    return this.http
      .post(this.baseUrl + "followUp/status", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  getNames(customerId: string, searchName: any): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.ResolutionText = searchName;

    return this.http.post(this.baseUrl + "MCR/names", this.apiInput);
  }

  public createAudit(cid, form, url): any {
    var auditRecord = new AuditRecord();
    auditRecord.CustID = cid;
    auditRecord.Form = form;
    auditRecord.URL = url;
    auditRecord.SessionId = sessionStorage.getItem("session");
    return this.http.post(this.baseUrl + "MCR/audit", auditRecord).subscribe(
      (res: any) => { },
      error => {
        console.log("error occured on auditing");
      }
    );
  }

  public getSubjectTypes(
    cid,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = cid;
    this.apiInput.LanguageCd = this.appStateService.language;
    return this.http
      .post(this.baseUrl + "MCR/subjecttype", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  public getSubjectRelationship(
    cid,
    mcrSuccessCallback,
    mcrErrorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = cid;
    this.apiInput.LanguageCd = this.appStateService.language;
    return this.http
      .post(this.baseUrl + "MCR/subjectrelationship", this.apiInput)
      .subscribe(
        (res: any) => {
          mcrSuccessCallback(res.responseData);
        },
        error => {
          mcrErrorCallback(error);
        }
      );
  }

  public getAttUser(cid, attUid, successCallback, errorCallback) {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = cid;
    this.apiInput.ResolutionText = attUid;
    return this.http
      .post(this.baseUrl + "MCR/attusers", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res);
        },
        error => {
          errorCallback(error);
        }
      )
  }

  public getEmployee(cid, empId, successCallback, errorCallback) {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = cid;
    this.apiInput.EmployeeUID = empId;
    return this.http
      .post(this.baseUrl + "MCR/employee", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res);
        },
        error => {
          errorCallback(error);
        }
      )
  }

  public getLocationFilterResult(customerId, searchTerm, successCallback, errorCallback) {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.ResolutionText = searchTerm;
    return this.http
      .post(this.baseUrl + "MCR/locations/filter", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res.responseData);
        },
        error => {
          errorCallback(error);
        }
      )
  }

  public getDefaultLanguages(successcallback: Function, errorcallback: Function) {
    return this.http.get(this.baseUrl + "MCR/defaultLanguages/")
      .subscribe(
        questions => {
          successcallback(questions);
        },
        error => {
          console.log("Some error occurred while getting data");
          errorcallback(error);
        }
      );
  }

  getLanguagesByCustomer(customerId, successCallback, errorCallback, noLangCallback) {
    this.getLanguages(
      customerId,
      res => {
        this.appStateService.languages = res;
        if (res && res.length > 0) {
          var lan = res.find(x => x.languageCD === this.appStateService.language)
            ? res.find(x => x.languageCD === this.appStateService.language)
            : res.find(x => x.languageCD === "en-US");
          if (lan) {
            this.appStateService.language = lan.languageCD;
          } else {
            this.appStateService.language = res[0].languageCD;
            this.appStateService.languageCd.next(res[0]);
          }
          successCallback();
        }
        else {
          noLangCallback();
        }
      },
      err => {
        errorCallback();
      }
    );
  }

  GetMasterCategories(
    customerId: string,
    language: string,
    successcallback: Function,
    errorcallback: Function
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.LanguageCd = language;
    return this.http
      .post(this.baseUrl + "MCR/masterCategories", this.apiInput)
      .subscribe(
        (res: any) => {
          successcallback(res);
        },
        error => errorcallback(error)
      );
  }

  GetSubCategories(
    customerId: string,
    language: string,
    categoryId,
    successcallback: Function,
    errorcallback: Function
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.LanguageCd = language;
    this.apiInput.CategoryId = categoryId;
    return this.http
      .post(this.baseUrl + "MCR/subCategories", this.apiInput)
      .subscribe(
        (res: any) => {
          successcallback(res);
        },
        error => errorcallback(error)
      );
  }

  groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      if (!result.length) { result = []; }
      let keyExist = result.findIndex(r => r["key"] == currentValue[key]);
      if (keyExist >= 0) {
        (result[keyExist]["value"]).push(currentValue);
      }
      else {
        let valueArray: Array<any> = [];
        valueArray.push(currentValue);
        result.push({ "key": currentValue[key], "value": valueArray });
      }
      return result;
    }, {});
  };

  GetIssuesCount(
    customerid: string,
    successcallback: Function,
    errorcallback: Function
  ): any {
    return this.http
      .get(this.baseUrl + "MCR/customer/" + customerid + "/issuescount")
      .subscribe(
        data => {
          successcallback(data);
        },
        error => errorcallback(error)
      );
  }

  public getCRSID(
    surveyID,
    respondentsID,
    customerId,
    successCallback,
    errorCallback
  ): any {
    this.apiInput = new APIInput();
    this.apiInput.CustomerId = customerId;
    this.apiInput.SurveyID = surveyID;
    this.apiInput.RespondentsID = respondentsID;
    return this.http
      .post(this.baseUrl + "MCR/getCRSID", this.apiInput)
      .subscribe(
        (res: any) => {
          successCallback(res.responseData);
        },
        error => {
          errorCallback(error);
        }
      );
  }

  //deletes file from azure temporary storage
  public deleteUploadedFiles(
    uuid: string,
    fileName: string,
    deleteFileSuccess: (responseData: any) => void,
    deleteFileFail: (error: any) => void
  )
  {
    const deleteFileInput = new DeleteFileInput();
    deleteFileInput.UniqueId = uuid;
    deleteFileInput.FileName = fileName; 

      return this.http.post(this.baseUrl + "followUp/deleteTemporaryFile/", deleteFileInput).subscribe(
      (res: any) => {
        deleteFileSuccess(res);
      },
      (error: any) => {
        deleteFileFail(error);
      }
    );
  }

   // Generates a unique GUID on client   
   generateGUID() {
    let myGuid = uuidv4();
    return myGuid;
  }
}
